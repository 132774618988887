import React, { useState, useEffect } from 'react';
import WeatherComponent from './weather';
import Timelapse from './Timelapse';
import { Camera, Clock, MapPin, Info, Settings, AlertCircle, Calendar, Eye, Battery, Signal, CloudOff, Clock as ClockIcon } from 'lucide-react';
import { WhatsAppButton } from './whatsapp';
import FeedStateControl from './FeedStateControl';
import FeedInfoCard from './FeedInfoCard';
import { collection, query, where, getDocs, limit, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase-config";

const LoadingState = () => (
  <div className="bg-white rounded-lg p-4 md:p-6 shadow-sm border border-gray-200">
    <div className="flex items-center justify-center space-x-2 md:space-x-3">
      <div className="animate-spin rounded-full h-4 w-4 md:h-5 md:w-5 border-b-2 border-blue-500"></div>
      <p className="text-sm md:text-base text-gray-600">Cargando información...</p>
    </div>
  </div>
);

const ErrorState = ({ message, user }) => (
  <div className="bg-white rounded-lg p-4 md:p-6 shadow-sm border border-gray-200">
    <div className="text-center space-y-3 md:space-y-4">
      <AlertCircle className="w-10 h-10 md:w-12 md:h-12 text-red-500 mx-auto" />
      <div>
        <h3 className="text-base md:text-lg font-semibold text-gray-800 mb-2">
          No se pudo cargar la información
        </h3>
        <p className="text-xs md:text-sm text-gray-600 mb-3 md:mb-4">
          {message || "Hubo un problema al cargar los datos del feed"}
        </p>
        <WhatsAppButton 
          message={`Hola, tengo problemas para ver la información de mi obra en LisualPro. Mi ID es ${user?.identifier}`}
          className="w-full"
        >
          Contactar Soporte
        </WhatsAppButton>
      </div>
    </div>
  </div>
);

const StatusIndicator = ({ isActive, text }) => (
  <span className={`inline-flex items-center px-1.5 py-0.5 md:px-2.5 md:py-0.5 rounded-full text-2xs md:text-xs font-medium ${
    isActive ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
  }`}>
    <span className={`h-1.5 w-1.5 md:h-2 md:w-2 rounded-full mr-1 md:mr-1.5 ${
      isActive ? 'bg-green-400' : 'bg-yellow-400'
    }`}></span>
    {text}
  </span>
);

const Dashboard = ({ user }) => {
  const [feedInfo, setFeedInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [daysInfo, setDaysInfo] = useState(null);
  const [daysLoading, setDaysLoading] = useState(true);
  const MAX_RETRIES = 3;
  const iframeRef = React.useRef(null);

  const translateState = (state) => {
    const translations = {
      'Active': 'Activa',
      'Inactive': 'Inactiva',
      'Capture': 'Capturando',
      'Offline': 'Desconectada',
      'Disabled': 'Deshabilitada'
    };
    return translations[state] || state;
  };

  useEffect(() => {
    const fetchFeedInfo = async () => {
      if (!user?.feedID) {
        if (retryCount < MAX_RETRIES) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 2000);
          return;
        }
        setError('No hay ID de feed disponible');
        setLoading(false);
        return;
      }

      try {
        const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
        const response = await fetch(
          `https://lisual-admin-backend-985418867286.us-central1.run.app/api/feed/info?` +
          `feedId=${user.feedID}&` +
          `apikey=${apiKey}`
        );

        if (!response.ok) {
          throw new Error('Error al obtener información del feed');
        }

        const data = await response.json();
        console.log('API Response:', data);
        setFeedInfo(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching feed info:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchFeedInfo();
  }, [user, retryCount]);

  // New useEffect to fetch days information
  useEffect(() => {
    const fetchDaysInfo = async () => {
      if (!user?.feedID) {
        setDaysInfo(null);
        setDaysLoading(false);
        return;
      }

      try {
        // Directly access the document by ID since they match
        const daysDocRef = doc(db, "feedDays", user.feedID);
        const daysDocSnap = await getDoc(daysDocRef);
        
        if (daysDocSnap.exists()) {
          const data = daysDocSnap.data();
          setDaysInfo(data);
          console.log("Days info loaded:", data);
        } else {
          console.log("No days information found for feedID:", user.feedID);
          setDaysInfo(null);
        }
        setDaysLoading(false);
      } catch (err) {
        console.error("Error fetching days information:", err);
        setDaysInfo(null);
        setDaysLoading(false);
      }
    };

    fetchDaysInfo();
  }, [user?.feedID]);

  const handleFeedStateChange = (newState) => {
    setFeedInfo(prev => {
      if (!prev) return prev;
      return {
        ...prev,
        feed: {
          ...prev.feed,
          feedState: newState
        }
      };
    });
    
    // Completely reload the page after a brief delay
    setTimeout(() => {
      console.log('Completely reloading page after state change to:', newState);
      window.location.reload();
    }, 1000);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No disponible';
    try {
      // Handle different date formats from Firebase
      let date;
      
      // Check if it's a Firebase Timestamp object
      if (dateString && typeof dateString === 'object' && dateString.seconds) {
        // Convert Firebase Timestamp to JS Date
        date = new Date(dateString.seconds * 1000);
      } else if (typeof dateString === 'string') {
        // Handle string date format
        date = new Date(dateString);
      } else if (typeof dateString === 'number') {
        // Handle timestamp in milliseconds
        date = new Date(dateString);
      } else {
        // Try direct Date object use
        date = dateString;
      }
      
      // Verify we have a valid date before formatting
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        console.error('Invalid date format:', dateString);
        return 'No disponible';
      }
      
      return date.toLocaleString('es-AR', {
        dateStyle: 'medium',
        timeStyle: 'short'
      });
    } catch (err) {
      console.error('Error formatting date:', err, dateString);
      return 'No disponible';
    }
  };

  const getConnectionQuality = () => {
    const interval = feedInfo?.feed?.capabilities?.MinFrameInterval || 0;
    if (interval === 0) return { icon: CloudOff, text: 'Sin conexión', color: 'text-red-500' };
    if (interval < 300) return { icon: Signal, text: 'Excelente', color: 'text-green-500' };
    if (interval < 600) return { icon: Signal, text: 'Buena', color: 'text-blue-500' };
    return { icon: Signal, text: 'Normal', color: 'text-green-500' };
  };

  const getCameraStatus = () => {
    const batteryLevel = 100; // This would come from the API if available
    const isLive = feedInfo?.feed?.liveState === 'Live';
    return {
      batteryLevel,
      isLive,
      lastCapture: feedInfo?.feed?.lastAcceptedFrameTime,
      quality: feedInfo?.feed?.capabilities?.MaxSizeCode
    };
  };

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error} user={user} />;
  }

  if (!user?.processedVideoUrl) {
    return null;
  }

  const connectionQuality = getConnectionQuality();
  const cameraStatus = getCameraStatus();
  const ConnectionIcon = connectionQuality.icon;

  return (
    <div className="space-y-6 px-1 md:px-0">
      {/* Days Left Progress Bar with integrated Feed State Control */}
      {daysInfo && typeof daysInfo.daysLeft === 'number' && typeof daysInfo.totalDays === 'number' && (
        <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 border border-blue-100">
          <div className="p-4 md:p-6">
            {/* Header with improved contrast - more responsive */}
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-3 md:mb-5">
              <div className="flex items-center mb-2 md:mb-0">
                <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center mr-2">
                  <ClockIcon className="w-4 h-4 text-white" />
                </div>
                <h3 className="text-base font-bold text-gray-900">Tiempo Restante de Suscripción</h3>
              </div>
              <div className="flex items-center text-xs bg-blue-50 px-2 py-1 rounded-full">
                <Calendar className="w-3 h-3 mr-1 text-blue-700" />
                <span className="text-blue-900 font-medium truncate">
                  Actualizado: {daysInfo.lastChecked ? formatDate(daysInfo.lastChecked) : 'No disponible'}
                </span>
              </div>
            </div>
            
            {/* Days remaining section with enhanced mobile layout */}
            <div className="w-full mb-4">
              <div className="md:flex md:items-center md:justify-between">
                <div className="flex items-center justify-between mb-2 md:mb-0">
                  <div>
                    <span className="text-xs font-semibold text-blue-700 block">Suscripción activa</span>
                    <div className="flex items-baseline mt-1">
                      <span className="text-3xl font-extrabold text-blue-900 tracking-tight mr-2">
                        {daysInfo.daysLeft}
                      </span>
                      <span className="text-sm font-medium text-gray-700">días restantes</span>
                    </div>
                  </div>
                  
                  <div className="md:hidden flex items-center ml-2">
                    <div className="flex items-center px-2 py-1 bg-blue-600 rounded-lg">
                      <span className="w-1.5 h-1.5 bg-white rounded-full mr-1 animate-pulse"></span>
                      <span className="text-xs font-medium text-white whitespace-nowrap">
                        {Math.round((1 - daysInfo.daysLeft / daysInfo.totalDays) * 100)}%
                      </span>
                    </div>
                  </div>
                </div>
                
                <div className="hidden md:flex md:items-center">
                  <div className="flex items-center px-2.5 py-1 bg-blue-600 rounded-lg">
                    <span className="w-2 h-2 bg-white rounded-full mr-1.5 animate-pulse"></span>
                    <span className="text-xs font-medium text-white whitespace-nowrap">
                      {Math.round((1 - daysInfo.daysLeft / daysInfo.totalDays) * 100)}% utilizado
                    </span>
                  </div>
                </div>
              </div>

              {/* Warning message for daysLeft = 0 */}
              {daysInfo.daysLeft === 0 && (
                <div className="mt-2 mb-3 bg-amber-50 border border-amber-200 rounded-lg p-3">
                  <div className="flex items-start">
                    <AlertCircle className="w-5 h-5 text-amber-500 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-amber-800 font-medium">
                        Tu suscripción ha expirado
                      </p>
                      <p className="text-xs text-amber-700 mt-0.5">
                        Si ya has realizado el pago, puedes ignorar este mensaje. El sistema se actualizará automáticamente.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              
              {/* Progress bar with enhanced contrast - mobile friendly height */}
              <div className="h-4 md:h-6 w-full bg-gray-200 rounded-full overflow-hidden shadow-inner mt-1.5">
                {/* Animated gradient progress bar with stronger colors - shows days USED */}
                <div 
                  className="h-full bg-gradient-to-r from-blue-600 via-blue-500 to-indigo-600 rounded-full transition-all duration-1000 ease-out flex items-center relative"
                  style={{ 
                    width: `${Math.max(5, (1 - daysInfo.daysLeft / daysInfo.totalDays) * 100)}%`,
                  }}
                >
                  {/* Enhanced glow effect at the end of the bar */}
                  <div className="absolute right-0 w-2 h-2 md:w-3 md:h-3 bg-white rounded-full shadow-sm transform translate-x-1/2 shadow-white/50"></div>
                </div>
              </div>
            </div>
            
            {/* Two-column layout: Stats and Camera Control in desktop, stacked in mobile */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              {/* Left column: Stats - Simplified card style to match the screenshot */}
              <div className="flex flex-row gap-3">
                <div className="flex-1 bg-white rounded-xl p-3 shadow-sm border border-blue-100">
                  <div className="flex items-center justify-center">
                    <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center">
                      <Clock className="w-4 h-4 text-white" />
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <span className="text-xs uppercase font-semibold text-blue-800 mb-1 block">PERÍODO</span>
                    <span className="text-lg font-bold text-blue-900 block">{daysInfo.totalDays - daysInfo.daysLeft} días</span>
                  </div>
                </div>
                
                <div className="flex-1 bg-white rounded-xl p-3 shadow-sm border border-blue-100">
                  <div className="flex items-center justify-center">
                    <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center">
                      <Calendar className="w-4 h-4 text-white" />
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <span className="text-xs uppercase font-semibold text-blue-800 mb-1 block">DURACIÓN</span>
                    <span className="text-lg font-bold text-blue-900 block">{daysInfo.totalDays} días</span>
                  </div>
                </div>
              </div>
              
              {/* Right column: Integrated Camera Control */}
              <div className="bg-white rounded-xl p-3 md:p-4 shadow-sm border border-gray-100">
                <div className="flex items-center gap-2 mb-3">
                  <Camera className="w-5 h-5 text-blue-600" />
                  <h3 className="font-semibold text-gray-800 text-sm md:text-base">Estado de la Cámara</h3>
                </div>
                
                {/* Camera control toggle styled to match design */}
                {feedInfo?.feed?.feedState && (
                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between w-full bg-gray-50 p-3 rounded-lg">
                      <div className="flex items-center w-16 md:w-auto">
                        <Camera className="w-4 h-4 md:w-5 md:h-5 text-green-600 mr-1.5" />
                        <span className="text-xs md:text-sm font-medium text-green-600 whitespace-nowrap">
                          Activa
                        </span>
                      </div>
                      
                      <button
                        onClick={() => handleFeedStateChange(
                          feedInfo.feed.feedState.toLowerCase() === 'capture' ? 'archive' : 'capture'
                        )}
                        className={`relative inline-flex h-6 md:h-7 w-11 md:w-14 items-center rounded-full transition-colors focus:outline-none ${
                          feedInfo.feed.feedState.toLowerCase() === 'capture' ? 'bg-green-500' : 'bg-gray-300'
                        }`}
                        role="switch"
                        aria-checked={feedInfo.feed.feedState.toLowerCase() === 'capture'}
                        aria-label="Cambiar estado de cámara"
                      >
                        <span
                          className={`inline-block h-4 w-4 md:h-5 md:w-5 transform rounded-full bg-white shadow-lg transition-transform ${
                            feedInfo.feed.feedState.toLowerCase() === 'capture' ? 'translate-x-1' : 'translate-x-6 md:translate-x-7'
                          }`}
                        />
                      </button>
                      
                      <div className="flex items-center w-20 md:w-auto justify-end">
                        <Clock className="w-4 h-4 md:w-5 md:h-5 text-gray-400 mr-1.5" />
                        <span className="text-xs md:text-sm font-medium text-gray-500 whitespace-nowrap">
                          En Reposo
                        </span>
                      </div>
                    </div>
                    
                    <div className="flex items-start px-2 text-xs text-gray-600 italic">
                      <AlertCircle className="w-3.5 h-3.5 text-blue-500 mr-1.5 flex-shrink-0 mt-0.5" />
                      <p>
                        {feedInfo.feed.feedState.toLowerCase() === 'capture' 
                          ? 'La cámara tomará fotos según lo programado.' 
                          : 'La cámara está en reposo y no capturará imágenes.'}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
        <FeedInfoCard title="Estado del Proyecto" icon={<Info className="w-5 h-5 text-blue-500" />}>
          <div className="space-y-2 md:space-y-3">
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Nombre del Proyecto:</span>
              <span className="text-xs md:text-sm text-gray-600 font-semibold truncate max-w-[60%] text-right">
                {feedInfo?.feed?.description?.Name || 'No disponible'}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Estado:</span>
              <StatusIndicator 
                isActive={feedInfo?.feed?.state === 'Active'} 
                text={translateState(feedInfo?.feed?.state)}
              />
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Última Actualización:</span>
              <span className="text-xs md:text-sm text-gray-600 truncate max-w-[60%] text-right">
                {formatDate(feedInfo?.feed?.lastAcceptedFrameTime)}
              </span>
            </div>
          </div>
        </FeedInfoCard>

        <FeedInfoCard title="Estadísticas de Captura" icon={<Calendar className="w-5 h-5 text-blue-500" />}>
          <div className="space-y-2 md:space-y-3">
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Resolución Máxima:</span>
              <span className="text-xs md:text-sm text-gray-600 truncate max-w-[60%] text-right">
                {feedInfo?.feed?.capabilities?.MaxSizeCode || 'No disponible'}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Estado del Feed:</span>
              <span className="text-xs md:text-sm text-gray-600 truncate max-w-[60%] text-right">
                {translateState(feedInfo?.feed?.feedState) || 'No disponible'}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-xs md:text-sm font-medium text-gray-700">Fecha de Última Captura:</span>
              <span className="text-xs md:text-sm text-gray-600 truncate max-w-[60%] text-right">
                {formatDate(feedInfo?.feed?.currentFrameTime)}
              </span>
            </div>
          </div>
        </FeedInfoCard>
      </div>
    </div>
  );
};

export default Dashboard; 