import React, { useState, useEffect } from 'react';
import { Cloud, Thermometer, Wind, Droplets, Sun, Moon, Sunrise, Sunset, AlertCircle, CloudRain, CloudSnow, CloudLightning, CloudFog, MapPin, Umbrella, Clock, Calendar, ArrowLeft, ArrowRight, ChevronDown, ChevronUp, BarChart } from 'lucide-react';
import { WhatsAppButton } from './whatsapp';

const WeatherComponent = ({ user }) => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('current');
  const [showDetails, setShowDetails] = useState(false);
  const [selectedForecastDay, setSelectedForecastDay] = useState(0);
  
  const getWeatherIcon = (code, size = "w-16 h-16") => {
    // Weather condition codes: https://openweathermap.org/weather-conditions
    if (code >= 200 && code < 300) return <CloudLightning className={`${size} text-yellow-400`} />;
    if (code >= 300 && code < 400) return <CloudRain className={`${size} text-blue-300`} />;
    if (code >= 500 && code < 600) return <CloudRain className={`${size} text-blue-500`} />;
    if (code >= 600 && code < 700) return <CloudSnow className={`${size} text-blue-200`} />;
    if (code >= 700 && code < 800) return <CloudFog className={`${size} text-gray-400`} />;
    if (code === 800) return <Sun className={`${size} text-yellow-500`} />;
    return <Cloud className={`${size} text-gray-400`} />;
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('es-ES', { weekday: 'short', month: 'short', day: 'numeric' });
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
  };

  // Process historical data from Open-Meteo format to match our component's format
  const processHistoricalData = (data) => {
    const historicalData = [];
    
    // Process daily data
    for (let i = 0; i < data.daily.time.length - 1; i++) { // Skip today
      const date = new Date(data.daily.time[i]);
      const timestamp = Math.floor(date.getTime() / 1000);
      
      // Find the index in hourly data for noon of this day
      const noonIndex = data.hourly.time.findIndex(time => 
        time.startsWith(data.daily.time[i]) && time.includes("12:00")
      );
      
      // Skip days where we can't get valid temperature data
      if (noonIndex === -1 && (!data.daily.temperature_2m_max || !data.daily.temperature_2m_max[i])) {
        console.log(`Skipping day ${data.daily.time[i]} due to missing temperature data`);
        continue;
      }
      
      // Only use actual data, not estimates
      const temp = noonIndex !== -1 
        ? data.hourly.temperature_2m[noonIndex] 
        : (data.daily.temperature_2m_max && data.daily.temperature_2m_max[i] ? data.daily.temperature_2m_max[i] : null);
        
      // Skip if we still can't get a valid temperature
      if (temp === undefined || temp === null) {
        console.log(`Skipping day ${data.daily.time[i]} due to invalid temperature: ${temp}`);
        continue;
      }
      
      const dayData = {
        dt: timestamp,
        temp: temp,
        feels_like: noonIndex !== -1 && data.hourly.apparent_temperature 
          ? data.hourly.apparent_temperature[noonIndex]
          : (data.daily.apparent_temperature_max ? data.daily.apparent_temperature_max[i] : temp - 2),
        humidity: noonIndex !== -1 ? data.hourly.relative_humidity_2m[noonIndex] : null,
        wind_speed: noonIndex !== -1 ? data.hourly.wind_speed_10m[noonIndex] / 3.6 : null, // Convert from km/h to m/s
        rain: noonIndex !== -1 && data.hourly.precipitation && data.hourly.precipitation[noonIndex] > 0 
          ? { '1h': data.hourly.precipitation[noonIndex] } 
          : null,
        weather: [{
          id: noonIndex !== -1 && data.hourly.precipitation && data.hourly.precipitation[noonIndex] > 0 ? 500 : 800,
          description: noonIndex !== -1 && data.hourly.precipitation && data.hourly.precipitation[noonIndex] > 0 
            ? "lluvia" 
            : "cielo despejado"
        }]
      };
      
      historicalData.push(dayData);
    }
    
    // Sort in chronological order (oldest to newest)
    return historicalData.sort((a, b) => a.dt - b.dt);
  };

  useEffect(() => {
    const fetchWeather = async () => {
      if (!user?.coordinates?.lat || !user?.coordinates?.lng) {
        setError('Datos de ubicación no disponibles');
        setLoading(false);
        return;
      }

      try {
        const API_KEY = "d64100e9637b3557caa33b3b4d31ad82";
        // Get current weather
        const currentResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${user.coordinates.lat}&lon=${user.coordinates.lng}&units=metric&lang=es&appid=${API_KEY}`
        );

        // Get forecast for 5 days
        const forecastResponse = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?lat=${user.coordinates.lat}&lon=${user.coordinates.lng}&units=metric&lang=es&appid=${API_KEY}`
        );

        if (!currentResponse.ok || !forecastResponse.ok) {
          throw new Error('Error al obtener datos del clima');
        }

        const currentData = await currentResponse.json();
        const forecastData = await forecastResponse.json();
        
        // Group forecast by days
        const groupedForecast = {};
        forecastData.list.forEach(item => {
          const date = new Date(item.dt * 1000).toLocaleDateString('es-ES');
          if (!groupedForecast[date]) {
            groupedForecast[date] = [];
          }
          groupedForecast[date].push(item);
        });

        // Process rain data properly
        const currentRain = currentData.rain ? (currentData.rain['1h'] || 0) : 0;
        
        // Initially set up with current and forecast data
        setWeather({
          current: currentData,
          forecast: forecastData.list,
          groupedForecast: Object.values(groupedForecast),
          currentRain,
          historicalData: [] // Will be populated by fetchHistoricalWeather
        });
        
        // Now fetch historical data separately
        try {
          await fetchHistoricalWeather();
        } catch (historyErr) {
          console.error("Error fetching historical data:", historyErr);
          // Instead of using simulated data, we'll just show what we have
          setWeather(prev => ({
            ...prev,
            historicalDataError: 'No pudimos cargar los datos históricos'
          }));
        }
        
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError('No se pudo obtener la información del clima');
        setLoading(false);
      }
    };

    fetchWeather();
  }, [user]);

  const fetchHistoricalWeather = async () => {
    try {
      // Open-Meteo historical data API endpoint
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 14); // Get up to 14 days of historical data
      
      const formatDate = (date) => date.toISOString().split('T')[0];
      
      const historyResponse = await fetch(
        `https://archive-api.open-meteo.com/v1/archive?` +
        `latitude=${user.coordinates.lat}&longitude=${user.coordinates.lng}` +
        `&start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}` +
        `&hourly=temperature_2m,relative_humidity_2m,precipitation,wind_speed_10m,apparent_temperature` +
        `&daily=temperature_2m_max,temperature_2m_min,precipitation_sum,apparent_temperature_max,apparent_temperature_min&timezone=auto`
      );
      
      if (!historyResponse.ok) {
        throw new Error('Error fetching historical data');
      }
      
      const historyData = await historyResponse.json();
      
      // Process this data into a format compatible with your component
      const processedHistoricalData = processHistoricalData(historyData);
      
      // Include it in your weather state
      setWeather(prev => ({
        ...prev,
        historicalData: processedHistoricalData,
        isHistoricalDataSimulated: false
      }));
      
    } catch (err) {
      console.error('Error fetching historical data:', err);
      throw err; // Re-throw to allow the caller to handle it
    }
  };

  if (!user?.coordinates?.lat || !user?.coordinates?.lng) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 transition-all duration-300">
        <div className="text-center space-y-4">
          <MapPin className="w-16 h-16 text-gray-400 mx-auto animate-pulse" />
          <h3 className="text-xl font-semibold text-gray-800">
            No hay datos de ubicación disponibles
          </h3>
          <p className="text-gray-600">
            Para poder mostrarte la información del clima, necesitamos la ubicación de tu obra.
          </p>
          <div className="max-w-sm mx-auto">
            <WhatsAppButton 
              message={`Hola, no puedo ver el clima en LisualPro porque no hay ubicación configurada. Mi ID es ${user.identifier}`}
              className="w-full transform hover:scale-105 transition-transform duration-300"
            >
              Contactar Soporte
            </WhatsAppButton>
          </div>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          <p className="mt-4 text-gray-600">Cargando información del clima...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 p-6 rounded-lg shadow-lg transition-all duration-300">
        <div className="flex items-center space-x-3">
          <AlertCircle className="text-red-500 w-6 h-6" />
          <p className="text-red-600 font-medium">{error}</p>
        </div>
        <div className="mt-4">
          <WhatsAppButton 
            message={`Hola, estoy teniendo problemas para ver el clima en LisualPro. Mi ID es ${user.identifier}`}
            className="w-full sm:w-auto transform hover:scale-105 transition-transform duration-300"
          >
            Contactar Soporte
          </WhatsAppButton>
        </div>
      </div>
    );
  }

  if (!weather) return null;

  const sunrise = formatTime(weather.current.sys.sunrise);
  const sunset = formatTime(weather.current.sys.sunset);

  // Calculate rain data
  const currentRain = weather.currentRain || 0;
  const nextHoursRain = weather.forecast.slice(0, 8).map(period => {
    return period.rain ? (period.rain['3h'] || 0) : 0;
  });
  const totalRainForecast = nextHoursRain.reduce((sum, rain) => sum + rain, 0);

  const renderCurrentWeather = () => (
    <div className="space-y-4 md:space-y-6 animate-fadeIn">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
        {/* Location and Main Weather Info */}
        <div className="space-y-3 md:space-y-4">
          <div className="flex items-center space-x-2 text-gray-600">
            <MapPin className="w-4 h-4 md:w-5 md:h-5" />
            <span className="text-xs md:text-sm font-medium truncate">{user.address || 'Ubicación no disponible'}</span>
          </div>
          
          <div className="flex items-center space-x-3 md:space-x-4 bg-gradient-to-r from-blue-50 to-indigo-50 p-3 md:p-4 rounded-lg transition-all duration-300 hover:shadow-md card-hover">
            {getWeatherIcon(weather.current.weather[0].id, "w-12 h-12 md:w-16 md:h-16")}
            <div>
              <div className="text-2xl md:text-4xl font-bold text-gray-800">{Math.round(weather.current.main.temp)}°C</div>
              <div className="text-sm md:text-lg text-gray-600 capitalize">{weather.current.weather[0].description}</div>
              <div className="text-xs md:text-sm text-gray-500">{formatDate(weather.current.dt)}</div>
            </div>
          </div>

          {/* Rain Information */}
          {(currentRain > 0 || totalRainForecast > 0) && (
            <div className="bg-blue-50 rounded-lg p-3 md:p-4 transition-all duration-300 hover:shadow-md card-hover">
              <div className="flex items-center space-x-2 mb-2 md:mb-3">
                <Umbrella className="w-4 h-4 md:w-5 md:h-5 text-blue-500" />
                <span className="font-medium text-blue-800 text-sm md:text-base">Información de Lluvia</span>
              </div>
              <div className="space-y-1 md:space-y-2">
                <div className="flex justify-between">
                  <span className="text-xs md:text-sm text-gray-600">Lluvia actual:</span>
                  <span className="text-xs md:text-sm font-medium">{currentRain.toFixed(1)} mm/h</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-xs md:text-sm text-gray-600">Próximas 24h:</span>
                  <span className="text-xs md:text-sm font-medium">{totalRainForecast.toFixed(1)} mm</span>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Weather Details */}
        <div>
          <div className="grid grid-cols-2 gap-2 md:gap-4 mb-2 md:mb-4">
            <div className="bg-gray-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:bg-gray-100 card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-gray-600">
                <Thermometer className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Sensación</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">
                {Math.round(weather.current.main.feels_like)}°C
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:bg-gray-100 card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-gray-600">
                <Wind className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Viento</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">
                {Math.round(weather.current.wind.speed * 3.6)} km/h
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:bg-gray-100 card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-gray-600">
                <Droplets className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Humedad</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">
                {weather.current.main.humidity}%
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:bg-gray-100 card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-gray-600">
                <Cloud className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Nubes</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">
                {weather.current.clouds.all}%
              </div>
            </div>
          </div>

          {/* Sun Times */}
          <div className="grid grid-cols-2 gap-2 md:gap-4">
            <div className="bg-gradient-to-r from-orange-50 to-yellow-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:shadow-md card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-orange-600">
                <Sunrise className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Amanecer</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">{sunrise}</div>
            </div>

            <div className="bg-gradient-to-r from-purple-50 to-indigo-50 rounded-lg p-2 md:p-3 transition-all duration-300 hover:shadow-md card-hover">
              <div className="flex items-center space-x-1 md:space-x-2 text-indigo-600">
                <Sunset className="w-4 h-4 md:w-5 md:h-5" />
                <span className="text-xs md:text-sm">Atardecer</span>
              </div>
              <div className="text-base md:text-xl font-semibold text-gray-800 mt-1">{sunset}</div>
            </div>
          </div>
        </div>
      </div>

      <button 
        onClick={() => setShowDetails(!showDetails)}
        className="w-full flex items-center justify-center bg-gray-100 hover:bg-gray-200 p-2 rounded-md mt-3 md:mt-4 transition-all duration-300"
      >
        <span className="mr-2 text-sm md:text-base">{showDetails ? 'Ocultar detalles' : 'Ver más detalles'}</span>
        {showDetails ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>
      
      {/* Additional details section that shows/hides based on showDetails state */}
      {showDetails && (
        <div className="animate-fadeIn space-y-4">
          <div className="bg-gray-50 p-3 md:p-4 rounded-lg">
            <h3 className="font-semibold text-gray-800 text-sm md:text-base mb-3">Detalles adicionales</h3>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4">
              <div className="bg-white rounded p-2 md:p-3 shadow-sm">
                <div className="text-xs md:text-sm text-gray-600">Presión</div>
                <div className="text-sm md:text-base font-medium">{weather.current.main.pressure} hPa</div>
              </div>
              
              <div className="bg-white rounded p-2 md:p-3 shadow-sm">
                <div className="text-xs md:text-sm text-gray-600">Visibilidad</div>
                <div className="text-sm md:text-base font-medium">{(weather.current.visibility / 1000).toFixed(1)} km</div>
              </div>
              
              <div className="bg-white rounded p-2 md:p-3 shadow-sm">
                <div className="text-xs md:text-sm text-gray-600">Dirección del viento</div>
                <div className="text-sm md:text-base font-medium">{weather.current.wind.deg}°</div>
              </div>
              
              <div className="bg-white rounded p-2 md:p-3 shadow-sm">
                <div className="text-xs md:text-sm text-gray-600">Índice UV</div>
                <div className="text-sm md:text-base font-medium">{weather.current.uvi || 'N/A'}</div>
              </div>
            </div>
          </div>
          
          {/* Location Map Section */}
          <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
            <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-3 md:mb-4">Esta es la dirección de tu obra</h3>
            <div className="relative w-full h-[250px] md:h-[300px] mb-3 md:mb-4">
              <iframe
                title="Project Location"
                className="w-full h-full rounded-lg"
                frameBorder="0"
                src={`https://www.openstreetmap.org/export/embed.html?bbox=${user.coordinates.lng-0.01}%2C${user.coordinates.lat-0.01}%2C${user.coordinates.lng+0.01}%2C${user.coordinates.lat+0.01}&layer=mapnik&marker=${user.coordinates.lat}%2C${user.coordinates.lng}`}
                allowFullScreen
              />
            </div>

            {/* Location Warning */}
            <div className="bg-yellow-50 p-3 md:p-4 rounded-lg">
              <div className="flex items-start">
                <AlertCircle className="w-5 h-5 text-yellow-600 mt-0.5 mr-2 md:mr-3 flex-shrink-0" />
                <div>
                  <h4 className="text-sm font-medium text-yellow-800">¿La ubicación no es correcta?</h4>
                  <p className="mt-1 text-xs md:text-sm text-yellow-700">
                    Si la ubicación mostrada no corresponde a tu obra, por favor contacta a soporte para actualizarla.
                  </p>
                  <WhatsAppButton 
                    message={`Hola, la ubicación de mi obra no es correcta en LisualPro. Mi ID es ${user.identifier}`}
                    className="mt-3 w-full md:w-auto text-sm"
                  >
                    Contactar Soporte
                  </WhatsAppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderForecastWeather = () => {
    if (!weather.groupedForecast || weather.groupedForecast.length === 0) {
      return (
        <div className="text-center py-10">
          <CloudRain className="mx-auto w-16 h-16 text-gray-400 mb-4" />
          <p className="text-gray-500">No hay datos de pronóstico disponibles</p>
        </div>
      );
    }

    const selectedDay = weather.groupedForecast[selectedForecastDay] || [];
    
    // Ensure we have temperatures before calculating
    if (!selectedDay.length || !selectedDay.some(h => h.main && typeof h.main.temp === 'number')) {
      return (
        <div className="text-center py-10">
          <AlertCircle className="mx-auto w-16 h-16 text-yellow-400 mb-4" />
          <p className="text-gray-500">No hay suficientes datos de temperatura para este día</p>
        </div>
      );
    }

    // Get min & max temps for proper scaling
    const validTemps = selectedDay.filter(h => h.main && typeof h.main.temp === 'number').map(h => h.main.temp);
    let tempMin = Math.min(...validTemps);
    let tempMax = Math.max(...validTemps);
    
    // Add some padding to ensure good visual distinction
    const padding = Math.max(2, (tempMax - tempMin) * 0.1);
    tempMin = Math.floor(tempMin - padding);
    tempMax = Math.ceil(tempMax + padding);
    
    // Ensure we have at least 5 degrees of range for visual clarity
    if (tempMax - tempMin < 5) {
      const mid = (tempMax + tempMin) / 2;
      tempMin = Math.floor(mid - 2.5);
      tempMax = Math.ceil(mid + 2.5);
    }
    
    const tempRange = tempMax - tempMin;

    return (
      <div className="space-y-4 md:space-y-6 animate-fadeIn max-w-full">
        {/* Day selector - improved for mobile with smooth scrolling */}
        <div className="relative overflow-hidden">
          <div className="overflow-x-auto py-2 scrollable-container hide-scrollbar -mx-2 px-2">
            <div className="flex space-x-2 min-w-0 flex-nowrap">
              {weather.groupedForecast.map((day, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedForecastDay(index)}
                  className={`flex-shrink-0 px-3 md:px-4 py-2 rounded-full text-xs md:text-sm transition-all duration-300 shadow-sm transform hover:scale-105 ${
                    selectedForecastDay === index 
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white font-medium' 
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {formatDate(day[0].dt)}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Weather chart for daily temp - improved for mobile */}
        <div className="bg-white rounded-lg shadow-md p-3 md:p-4 mb-3 md:mb-4 w-full transform transition-all duration-300 hover:shadow-lg">
          <h3 className="font-semibold text-gray-800 mb-2 md:mb-3 text-sm md:text-base">Temperatura durante el día</h3>
          
          {/* Min/max temperature indicators and range */}
          <div className="flex justify-between text-xs text-gray-600 mb-1">
            <span className="font-medium">Min: {tempMin.toFixed(1)}°C</span>
            <span className="font-medium">Max: {tempMax.toFixed(1)}°C</span>
          </div>
          
          <div className="h-36 md:h-48 relative mb-6 border-b border-gray-200">
            {/* Temperature grid lines */}
            <div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
              {[0, 0.25, 0.5, 0.75, 1].map((fraction, i) => (
                <div key={i} className="border-t border-gray-100 w-full h-0">
                  <span className="absolute -left-1 -top-2 text-[10px] md:text-xs text-gray-400">
                    {Math.round(tempMax - (tempRange * fraction))}°
                  </span>
                </div>
              ))}
            </div>
            
            {/* Bars container with absolute positioned bars - improved touch targets */}
            <div className="absolute bottom-0 left-0 right-0 flex justify-between h-full">
              {selectedDay.map((hour, idx) => {
                // Calculate height as percentage of temperature range
                const heightPercent = ((hour.main.temp - tempMin) / tempRange) * 100;
                
                return (
                <div 
                  key={idx} 
                  className="flex flex-col items-center group relative z-10 touch-manipulation"
                  style={{ flex: '1 1 0%' }}
                >
                  <div 
                    className="text-xs bg-blue-500 text-white px-1 py-0.5 rounded absolute -top-6 md:-top-8 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap transform -translate-x-1/2 left-1/2 shadow-md"
                  >
                    {Math.round(hour.main.temp)}°C a las {formatTime(hour.dt)}
                  </div>
                  <div 
                    className="bg-gradient-to-t from-blue-400 to-blue-300 hover:from-blue-500 hover:to-blue-400 rounded-t w-[80%] md:w-4/5 absolute bottom-0 transition-all duration-300 ease-in-out"
                    style={{ 
                      height: `${Math.max(5, heightPercent)}%`,
                    }}
                  ></div>
                  <div className="text-[10px] md:text-xs text-gray-500 absolute bottom-[-18px] md:bottom-[-24px] truncate w-full text-center">
                    {formatTime(hour.dt).split(':')[0]}h
                  </div>
                </div>
              )})}
            </div>
          </div>
        </div>

        {/* Hourly forecast - improved scrolling on mobile with better visuals */}
        <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow-md w-full overflow-hidden transform transition-all duration-300 hover:shadow-lg">
          <div className="overflow-x-auto scrollable-container hide-scrollbar py-2 w-full">
            <div className="flex p-2 md:p-4 min-w-max">
              {selectedDay.map((hour, idx) => (
                <div 
                  key={idx} 
                  className="flex flex-col items-center mx-2 md:mx-4 first:ml-0 last:mr-0 transition-transform duration-300 hover:scale-110 touch-manipulation bg-white bg-opacity-30 rounded-lg p-2 md:p-3 backdrop-filter backdrop-blur-sm shadow-sm"
                >
                  <span className="text-xs md:text-sm text-gray-600 font-medium">{formatTime(hour.dt)}</span>
                  {getWeatherIcon(hour.weather[0].id, "w-8 h-8 md:w-10 md:h-10")}
                  <span className="font-bold text-sm md:text-base">{Math.round(hour.main.temp)}°C</span>
                  <div className="text-[10px] md:text-xs text-center text-gray-600 mt-1 max-w-[80px] truncate">
                    {hour.weather[0].description}
                  </div>
                  <div className="flex items-center mt-2 justify-center">
                    <Droplets className="w-3 h-3 text-blue-500 mr-1" />
                    <span className="text-[10px] md:text-xs">{hour.main.humidity}%</span>
                  </div>
                  <div className="flex items-center mt-1 justify-center">
                    <Wind className="w-3 h-3 text-gray-500 mr-1" />
                    <span className="text-[10px] md:text-xs">{Math.round(hour.wind.speed * 3.6)} km/h</span>
                  </div>
                  {hour.pop > 0 && (
                    <div className="flex items-center mt-1 justify-center">
                      <Umbrella className="w-3 h-3 text-blue-500 mr-1" />
                      <span className="text-[10px] md:text-xs">{Math.round(hour.pop * 100)}%</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Day overview - improved for mobile */}
        <div className="bg-white rounded-lg shadow-md p-3 md:p-4 transform transition-all duration-300 hover:shadow-lg">
          <h3 className="font-semibold text-gray-800 mb-2 md:mb-3 text-sm md:text-base">Resumen del día</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
            {/* Summary for the selected day */}
            <div className="col-span-1 md:col-span-1">
              <div className="space-y-2 md:space-y-3">
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <Thermometer className="w-3 h-3 md:w-4 md:h-4 mr-1 text-red-500" />
                    Temperatura máxima:
                  </span>
                  <span className="text-xs md:text-sm font-medium">{Math.max(...selectedDay.map(h => h.main.temp_max)).toFixed(1)}°C</span>
                </div>
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <Thermometer className="w-3 h-3 md:w-4 md:h-4 mr-1 text-blue-500" />
                    Temperatura mínima:
                  </span>
                  <span className="text-xs md:text-sm font-medium">{Math.min(...selectedDay.map(h => h.main.temp_min)).toFixed(1)}°C</span>
                </div>
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <Droplets className="w-3 h-3 md:w-4 md:h-4 mr-1 text-blue-500" />
                    Humedad promedio:
                  </span>
                  <span className="text-xs md:text-sm font-medium">
                    {Math.round(selectedDay.reduce((sum, h) => sum + h.main.humidity, 0) / selectedDay.length)}%
                  </span>
                </div>
              </div>
            </div>
            
            <div className="col-span-1 md:col-span-1">
              <div className="space-y-2 md:space-y-3">
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <Wind className="w-3 h-3 md:w-4 md:h-4 mr-1 text-gray-500" />
                    Viento máximo:
                  </span>
                  <span className="text-xs md:text-sm font-medium">
                    {Math.max(...selectedDay.map(h => h.wind.speed * 3.6)).toFixed(1)} km/h
                  </span>
                </div>
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <Umbrella className="w-3 h-3 md:w-4 md:h-4 mr-1 text-blue-600" />
                    Probabilidad de lluvia:
                  </span>
                  <span className="text-xs md:text-sm font-medium">
                    {selectedDay.some(h => h.pop > 0) 
                      ? `${Math.round(Math.max(...selectedDay.map(h => h.pop * 100)))}%` 
                      : '0%'}
                  </span>
                </div>
                <div className="flex justify-between items-center bg-gray-50 p-2 rounded-md">
                  <span className="text-xs md:text-sm text-gray-600 flex items-center">
                    <CloudRain className="w-3 h-3 md:w-4 md:h-4 mr-1 text-blue-500" />
                    Lluvia acumulada:
                  </span>
                  <span className="text-xs md:text-sm font-medium">
                    {selectedDay.reduce((sum, h) => sum + (h.rain?.['3h'] || 0), 0).toFixed(1)} mm
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHistoricalWeather = () => {
    if (weather.historicalDataError) {
      return (
        <div className="text-center py-10">
          <AlertCircle className="mx-auto w-16 h-16 text-orange-400 mb-4" />
          <p className="text-gray-700">{weather.historicalDataError}</p>
          <button 
            onClick={() => fetchHistoricalWeather().catch(() => {})}
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition-colors duration-300"
          >
            Intentar nuevamente
          </button>
        </div>
      );
    }

    if (!weather.historicalData || weather.historicalData.length === 0) {
      return (
        <div className="text-center py-10">
          <Clock className="mx-auto w-16 h-16 text-gray-400 mb-4" />
          <p className="text-gray-500">No hay datos históricos disponibles</p>
        </div>
      );
    }

    // Better organize the historical data and filter out invalid data points
    const historicalData = [...weather.historicalData]
      .filter(day => day && day.temp !== null && day.temp !== undefined);
    
    // Check if we have valid data after filtering
    if (historicalData.length === 0) {
      return (
        <div className="text-center py-10">
          <AlertCircle className="mx-auto w-16 h-16 text-yellow-400 mb-4" />
          <p className="text-gray-500">No hay datos históricos válidos disponibles</p>
        </div>
      );
    }
    
    // Calculate temperature range for better scaling with valid values only
    const temps = historicalData.map(day => day.temp).filter(t => t !== null && t !== undefined);
    let minTemp = Math.min(...temps);
    let maxTemp = Math.max(...temps);
    
    // Add some padding for visual distinction
    const padding = Math.max(2, (maxTemp - minTemp) * 0.1);
    minTemp = Math.floor(minTemp - padding);
    maxTemp = Math.ceil(maxTemp + padding);
    
    // Ensure we have at least 5 degrees of range for visual clarity
    if (maxTemp - minTemp < 5) {
      const mid = (maxTemp + minTemp) / 2;
      minTemp = Math.floor(mid - 2.5);
      maxTemp = Math.ceil(mid + 2.5);
    }
    
    const tempRange = maxTemp - minTemp;

    return (
      <div className="space-y-4 md:space-y-6 animate-fadeIn">
        <div className="bg-white rounded-lg shadow-md p-3 md:p-4 w-full">
          <div className="flex items-center justify-between mb-2 md:mb-4">
            <div className="flex items-center">
              <BarChart className="w-4 h-4 md:w-5 md:h-5 text-blue-500 mr-1 md:mr-2" />
              <h3 className="font-semibold text-sm md:text-lg text-gray-800">Tendencias de temperatura</h3>
            </div>
          </div>
          
          {/* Min/max temperature indicators */}
          <div className="flex justify-between text-[10px] md:text-xs text-gray-600 mb-1">
            <span className="font-medium">Min: {minTemp.toFixed(1)}°C</span>
            <span className="font-medium">Max: {maxTemp.toFixed(1)}°C</span>
          </div>
          
          {/* Chart with grid lines - improved for mobile */}
          <div className="h-36 md:h-48 relative mb-6 border-b border-gray-200 w-full">
            {/* Temperature grid lines */}
            <div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
              {[0, 0.25, 0.5, 0.75, 1].map((fraction, i) => (
                <div key={i} className="border-t border-gray-100 w-full h-0">
                  <span className="absolute -left-1 -top-2 text-[10px] md:text-xs text-gray-400">
                    {Math.round(maxTemp - (tempRange * fraction))}°
                  </span>
                </div>
              ))}
            </div>
            
            {/* Bars container with absolute positioned bars - improved touch targets */}
            <div className="absolute bottom-0 left-0 right-0 flex justify-between h-full">
              {historicalData.map((day, idx) => {
                // Calculate height as percentage of temperature range
                const heightPercent = ((day.temp - minTemp) / tempRange) * 100;
                
                return (
                <div key={idx} className="flex flex-col items-center group relative z-10 touch-manipulation" style={{ flex: '1 1 0%' }}>
                  <div 
                    className="bg-gradient-to-t from-blue-400 to-blue-300 hover:from-blue-500 hover:to-blue-400 rounded-t w-[80%] md:w-4/5 absolute bottom-0"
                    style={{ 
                      height: `${Math.max(5, heightPercent)}%`
                    }}
                  >
                  </div>
                  <div className="absolute -top-6 md:-top-8 left-1/2 transform -translate-x-1/2 text-[10px] md:text-xs bg-blue-500 text-white px-1 py-0.5 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap shadow-md">
                    {Math.round(day.temp)}°C - {formatDate(day.dt).split(',')[0]}
                  </div>
                  <div className="text-[10px] md:text-xs text-gray-500 absolute bottom-[-18px] md:bottom-[-24px] w-full text-center truncate font-medium">
                    {formatDate(day.dt).split(',')[0]}
                  </div>
                </div>
              )})}
            </div>
          </div>
        </div>

        <h3 className="font-semibold text-lg md:text-xl text-gray-800 mt-4 md:mt-6">Registro de los últimos días</h3>
        
        <div className="space-y-3 md:space-y-4">
          {historicalData.map((day, idx) => (
            <div 
              key={idx} 
              className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg transform hover:translate-y-[-2px]"
            >
              <div className="bg-gradient-to-r from-indigo-50 to-purple-50 px-3 md:px-4 py-2 md:py-3">
                <h4 className="font-medium text-sm md:text-base text-gray-800">{formatDate(day.dt)}</h4>
              </div>
              
              <div className="p-3 md:p-4">
                <div className="flex items-center space-x-3 md:space-x-4 mb-3 md:mb-4">
                  {getWeatherIcon(day.weather[0].id, "w-10 h-10 md:w-12 md:h-12")}
                  <div>
                    <div className="text-xl md:text-2xl font-bold text-gray-800">{Math.round(day.temp)}°C</div>
                    <div className="text-xs md:text-sm text-gray-600 capitalize">{day.weather[0].description}</div>
                  </div>
                </div>
                
                <div className="grid grid-cols-2 gap-2 md:gap-3">
                  {day.feels_like !== null && (
                    <div className="bg-gray-50 rounded p-2">
                      <div className="flex items-center space-x-1 text-gray-600">
                        <Thermometer className="w-3 h-3 md:w-4 md:h-4" />
                        <span className="text-xs md:text-sm">Sensación</span>
                      </div>
                      <div className="text-xs md:text-sm font-medium">{Math.round(day.feels_like)}°C</div>
                    </div>
                  )}
                  
                  {day.wind_speed !== null && (
                    <div className="bg-gray-50 rounded p-2">
                      <div className="flex items-center space-x-1 text-gray-600">
                        <Wind className="w-3 h-3 md:w-4 md:h-4" />
                        <span className="text-xs md:text-sm">Viento</span>
                      </div>
                      <div className="text-xs md:text-sm font-medium">{Math.round(day.wind_speed * 3.6)} km/h</div>
                    </div>
                  )}
                  
                  {day.humidity !== null && (
                    <div className="bg-gray-50 rounded p-2">
                      <div className="flex items-center space-x-1 text-gray-600">
                        <Droplets className="w-3 h-3 md:w-4 md:h-4" />
                        <span className="text-xs md:text-sm">Humedad</span>
                      </div>
                      <div className="text-xs md:text-sm font-medium">{Math.round(day.humidity)}%</div>
                    </div>
                  )}
                  
                  {day.rain && (
                    <div className="bg-gray-50 rounded p-2">
                      <div className="flex items-center space-x-1 text-gray-600">
                        <Umbrella className="w-3 h-3 md:w-4 md:h-4" />
                        <span className="text-xs md:text-sm">Lluvia</span>
                      </div>
                      <div className="text-xs md:text-sm font-medium">{day.rain['1h'].toFixed(1)} mm</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="relative w-full mx-auto bg-white rounded-lg shadow-lg p-2 sm:p-4 md:p-6 touch-manipulation">
      {/* Enhanced CSS for better mobile support */}
      <style jsx="true">{`
        .weather-container {
          width: 100%;
          max-width: 100%;
          overflow-x: hidden;
          box-sizing: border-box;
        }
        .scrollable-container {
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .scrollable-container::-webkit-scrollbar {
          display: none;
        }
        .hide-scrollbar {
          -webkit-overflow-scrolling: touch;
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out;
        }
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @media (max-width: 640px) {
          .chart-container {
            margin: 0 -8px;
            padding: 0 8px;
            width: calc(100% + 16px);
          }
        }
        .card-hover {
          transition: all 0.3s ease;
        }
        .card-hover:hover {
          transform: translateY(-3px);
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
        }
      `}</style>
      
      {/* Content Container with improved spacing */}
      <div className="weather-container space-y-3 sm:space-y-4">
        {/* Navigation tabs - improved touch targets */}
        <div className="flex w-full border-b border-gray-200 scrollable-container">
          <button
            onClick={() => setActiveTab('current')}
            className={`px-2 sm:px-3 md:px-4 py-2 flex items-center flex-shrink-0 ${
              activeTab === 'current'
                ? 'text-blue-600 border-b-2 border-blue-500 font-medium'
                : 'text-gray-600 hover:text-gray-800'
            } transition-colors duration-300 whitespace-nowrap text-sm min-h-[44px]`}
          >
            <Sun className="w-4 h-4 md:w-5 md:h-5 mr-1" />
            <span>Clima Actual</span>
          </button>
          <button
            onClick={() => setActiveTab('forecast')}
            className={`px-2 sm:px-3 md:px-4 py-2 flex items-center flex-shrink-0 ${
              activeTab === 'forecast'
                ? 'text-blue-600 border-b-2 border-blue-500 font-medium'
                : 'text-gray-600 hover:text-gray-800'
            } transition-colors duration-300 whitespace-nowrap text-sm min-h-[44px]`}
          >
            <Calendar className="w-4 h-4 md:w-5 md:h-5 mr-1" />
            <span>Pronóstico</span>
          </button>
          <button
            onClick={() => setActiveTab('historical')}
            className={`px-2 sm:px-3 md:px-4 py-2 flex items-center flex-shrink-0 ${
              activeTab === 'historical'
                ? 'text-blue-600 border-b-2 border-blue-500 font-medium'
                : 'text-gray-600 hover:text-gray-800'
            } transition-colors duration-300 whitespace-nowrap text-sm min-h-[44px]`}
          >
            <Clock className="w-4 h-4 md:w-5 md:h-5 mr-1" />
            <span>Historial</span>
          </button>
        </div>

        {/* Content area */}
        <div className="min-h-[350px] w-full">
          {activeTab === 'current' && renderCurrentWeather()}
          {activeTab === 'forecast' && renderForecastWeather()}
          {activeTab === 'historical' && renderHistoricalWeather()}
        </div>
      </div>
    </div>
  );
};

export default WeatherComponent;