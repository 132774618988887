import React, { useRef, useState, useCallback, useEffect } from "react";
import { Download, Camera, RefreshCw, Image as ImageIcon, MessageSquare, Wand2, Save, Settings, ChevronDown, ChevronUp, Calendar, AlertCircle, CheckCircle, X, Info } from 'lucide-react';

const ConstructionAICaptionMaker = ({ user, selectedDate }) => {
  // States for managing the application - moved to the top
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [constructionImages, setConstructionImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);
  const [aiCaption, setAiCaption] = useState("");
  const [generatedImage, setGeneratedImage] = useState(null);
  const [generatingCaption, setGeneratingCaption] = useState(false);
  const [generatingImage, setGeneratingImage] = useState(false);
  const [currentDate, setCurrentDate] = useState(selectedDate || new Date());
  
  // Prompt selection states
  const [usePredefinedCaptionPrompt, setUsePredefinedCaptionPrompt] = useState(true);
  const [usePredefinedImagePrompt, setUsePredefinedImagePrompt] = useState(true);
  
  // Predefined prompts
  const predefinedCaptionPrompt = "Compórtate como un especialista en comunicación y marketing de una empresa constructora. Analiza la imagen proporcionada y genera un copy para una publicación de Instagram, estructurado con hook, story y CTA, pero sin mencionar estas secciones en la respuesta. Identifica con precisión las subtareas de construcción que se están llevando a cabo y descríbelas con un tono profesional, resaltando el progreso, la calidad y el compromiso del equipo en cada fase de la obra. Evita el uso de emojis, negritas o asteriscos. La respuesta debe ser el texto listo para ser publicado.";
  const predefinedImagePrompt = "Analiza la imagen proporcionada y comprende las actividades de construcción que se están llevando a cabo. Genera una imagen en formato 9:16 que represente de manera hiperrealista a un hombre ejecutando una de las tareas observadas en la foto original. Asegúrate de que el entorno y los elementos arquitectónicos sean IDENTICOS con la imagen de referencia. La persona debe portar siempre los elementos de protección personal adecuados, como casco, guantes y chaleco reflectante. La imagen debe ser lo más detallada y cercana posible, con un encuadre tipo plano americano, resaltando la acción principal. Aplica un efecto bokeh en el fondo para destacar al sujeto y generar una sensación de profundidad. La escena debe ser de día, manteniendo las mismas condiciones climáticas que la imagen original, asegurando coherencia en la iluminación y la atmósfera. La persona representada debe ser un hombre de piel clara, con rasgos occidentales, y siempre debe estar de espaldas, sin que se le vea la cara en ningún caso. Además, asegúrate de que no se vea la línea del horizonte; la vista debe estar enfocada hacia abajo, específicamente hacia la tarea que está realizando, para mantener el foco en la acción sin mostrar el entorno lejano.";
  
  // Custom prompts (user editable)
  const [customCaptionPrompt, setCustomCaptionPrompt] = useState(predefinedCaptionPrompt);
  const [customImagePrompt, setCustomImagePrompt] = useState(predefinedImagePrompt);
  
  // Active prompts (what's actually used in the API calls)
  const [captionPrompt, setCaptionPrompt] = useState(predefinedCaptionPrompt);
  const [imagePrompt, setImagePrompt] = useState(predefinedImagePrompt);
  
  const [toast, setToast] = useState({ visible: false, message: '', type: 'success' });
  const [imageZoom, setImageZoom] = useState(false);
  
  // Constants for API configuration
  const apiBaseUrl = "https://lisual-admin-backend-985418867286.us-central1.run.app/api";
  const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
  
  // Using environment variables for API keys
  const [apiKeys, setApiKeys] = useState({
    openaiKey: process.env.REACT_APP_OPENAI_API_KEY || "",
    dalleKey: process.env.REACT_APP_DALLE_API_KEY || ""
  });
  
  // Effect to update active prompts when selection mode changes
  useEffect(() => {
    setCaptionPrompt(usePredefinedCaptionPrompt ? predefinedCaptionPrompt : customCaptionPrompt);
  }, [usePredefinedCaptionPrompt, customCaptionPrompt]);
  
  useEffect(() => {
    setImagePrompt(usePredefinedImagePrompt ? predefinedImagePrompt : customImagePrompt);
  }, [usePredefinedImagePrompt, customImagePrompt]);
  
  // Function to test API keys
  const testApiKeys = async () => {
    setError(null);
    
    // Test OpenAI API
    if (apiKeys.openaiKey) {
      try {
        showToast("Verificando clave de OpenAI...", 'info');
        const response = await fetch('https://api.openai.com/v1/models', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${apiKeys.openaiKey}`
          }
        });
        
        if (response.ok) {
          showToast("Clave de OpenAI válida", 'success');
          console.log('OpenAI API key is valid');
        } else {
          const errorData = await response.json();
          showToast(`Error en clave de OpenAI: ${errorData.error?.message || response.statusText}`, 'error');
          console.error('OpenAI API key error:', errorData);
        }
      } catch (error) {
        showToast(`Error al verificar clave de OpenAI: ${error.message}`, 'error');
        console.error('OpenAI API test error:', error);
      }
    } else {
      showToast("No hay clave de OpenAI configurada", 'error');
    }
    
    // Test DALL-E API (using the same endpoint as OpenAI since they share the API)
    if (apiKeys.dalleKey) {
      try {
        showToast("Verificando clave de DALL-E...", 'info');
        const response = await fetch('https://api.openai.com/v1/models', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${apiKeys.dalleKey}`
          }
        });
        
        if (response.ok) {
          showToast("Clave de DALL-E válida", 'success');
          console.log('DALL-E API key is valid');
        } else {
          const errorData = await response.json();
          showToast(`Error en clave de DALL-E: ${errorData.error?.message || response.statusText}`, 'error');
          console.error('DALL-E API key error:', errorData);
        }
      } catch (error) {
        showToast(`Error al verificar clave de DALL-E: ${error.message}`, 'error');
        console.error('DALL-E API test error:', error);
      }
    } else {
      showToast("No hay clave de DALL-E configurada", 'error');
    }
  };

  const effectiveDate = currentDate;
  
  // Add custom animation styles
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(10px); }
        to { opacity: 1; transform: translateY(0); }
      }
      .animate-fadeIn {
        animation: fadeIn 0.5s ease-out forwards;
      }
      
      @keyframes slideIn {
        from { transform: translateX(-20px); opacity: 0; }
        to { transform: translateX(0); opacity: 1; }
      }
      .animate-slideIn {
        animation: slideIn 0.3s ease-out forwards;
      }
      
      @keyframes scaleIn {
        from { transform: scale(0.95); opacity: 0; }
        to { transform: scale(1); opacity: 1; }
      }
      .animate-scaleIn {
        animation: scaleIn 0.3s ease-out forwards;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  // Debug props received
  console.log('Instagram component props:', { user, selectedDate });
  
  // Check for required props
  if (!user) {
    console.error('Instagram component missing required "user" prop');
  }
  
  // Fallback image
  const fallbackImageUrl = 'data:image/svg+xml;base64,' + btoa(`
    <svg width="300" height="200" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#f3f4f6"/>
      <text x="50%" y="50%" font-family="Arial" font-size="16" fill="#6b7280" text-anchor="middle">
        Error al cargar imagen
      </text>
    </svg>
  `);
  
  // Cache configuration
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  const cache = new Map();
  
  // Format date for API requests
  const formatDate = (date) => {
    return date.toISOString().replace(/\.\d{3}Z$/, 'Z');
  };

  // Handle date change
  const handleDateChange = (e) => {
    const newDate = new Date(e.target.value);
    setCurrentDate(newDate);
    setSelectedImage(null); // Reset selected image when date changes
    setAiCaption(""); // Reset generated caption
    setGeneratedImage(null); // Reset generated image
  };

  // Get date string for input element
  const getDateString = (date) => {
    return date.toISOString().split('T')[0];
  };
  
  // Function to navigate to previous/next day
  const navigateDay = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + direction);
    setCurrentDate(newDate);
    setSelectedImage(null); // Reset selected image when date changes
    setAiCaption(""); // Reset generated caption
    setGeneratedImage(null); // Reset generated image
  };
  
  // Error handling function
  const handleApiError = (status) => {
    const errorMessages = {
      400: "Parámetros de solicitud inválidos. Por favor, compruebe el rango de fechas.",
      401: "Acceso no autorizado. Por favor, compruebe su clave API.",
      403: "Permisos insuficientes para acceder a este feed.",
      404: "Feed o imágenes no encontradas.",
      500: "Error del servidor. Por favor, inténtelo de nuevo más tarde.",
      503: "Servicio temporalmente no disponible.",
      504: "Tiempo de espera de la solicitud agotado. Por favor, inténtelo de nuevo."
    };
    return errorMessages[status] || `Error: ${status}`;
  };
  
  // Cache management functions
  const getCachedData = (key) => {
    const cached = cache.get(key);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return cached.data;
    }
    cache.delete(key);
    return null;
  };

  const setCachedData = (key, data) => {
    cache.set(key, {
      data,
      timestamp: Date.now()
    });
  };
  
  // Parse timestamps from API response (from ImageGallery.js)
  const parseTimestamps = (data) => {
    if (!data) return [];
    
    // If it's already an array of timestamps
    if (Array.isArray(data)) {
      return data;
    }
    
    // Handle nested frames structure: { frames: { Frames: [...] } }
    if (data.frames && data.frames.Frames && Array.isArray(data.frames.Frames)) {
      return data.frames.Frames;
    }
    
    // If it's an object with frames property
    if (data.frames && Array.isArray(data.frames)) {
      return data.frames;
    }
    
    // If it's an object with timestamps
    if (data.timestamps && Array.isArray(data.timestamps)) {
      return data.timestamps;
    }

    // If it's an object with Frames property
    if (data.Frames && Array.isArray(data.Frames)) {
      return data.Frames;
    }

    // If it's an object with a different structure, try to extract timestamps
    if (typeof data === 'object') {
      // Recursively search for arrays in nested objects
      const findTimestamps = (obj) => {
        for (const key in obj) {
          if (Array.isArray(obj[key]) && obj[key].length > 0 && typeof obj[key][0] === 'string') {
            return obj[key];
          }
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            const result = findTimestamps(obj[key]);
            if (result) return result;
          }
        }
        return null;
      };
      
      const timestamps = findTimestamps(data);
      if (timestamps) return timestamps;
    }

    console.error('Formato de respuesta API inesperado:', data);
    return [];
  };

  // Function to show toast notification
  const showToast = (message, type = 'success') => {
    setToast({ visible: true, message, type });
    setTimeout(() => {
      setToast({ ...toast, visible: false });
    }, 3000);
  };
  
  // Update fetchConstructionImages to show toast notifications
  const fetchConstructionImages = useCallback(async () => {
    try {
      setImagesLoading(true);
      setError(null);
      
      // Create start and end of day in UTC
      const start = new Date(effectiveDate);
      start.setUTCHours(0, 0, 0, 0);
      const end = new Date(effectiveDate);
      end.setUTCHours(23, 59, 59, 999);
      
      // Check cache first
      const cacheKey = `${user.feedID}-${start.toISOString()}`;
      const cachedImages = getCachedData(cacheKey);
      if (cachedImages) {
        console.log('Using cached images:', cachedImages.length);
        setConstructionImages(cachedImages);
        setImagesLoading(false);
        return;
      }
      
      // Construct URL with proper parameters
      const params = new URLSearchParams({
        feedId: user.feedID,
        startTime: formatDate(start),
        endTime: formatDate(end),
        apikey: apiKey,
        interval: '3600' // 1 hour interval in seconds
      });
      
      // Make a direct API request
      const apiUrl = `${apiBaseUrl}/feed/frames?${params.toString()}`;
      
      console.log('Obteniendo imágenes desde:', apiUrl);
      
      const response = await fetch(apiUrl, {
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error de respuesta API:', errorText);
        throw new Error(handleApiError(response.status));
      }
      
      const data = await response.json();
      console.log('Respuesta API:', data);
      
      // Parse timestamps from the response
      const timestamps = parseTimestamps(data);
      console.log('Parsed timestamps:', timestamps);
      
      if (timestamps.length === 0) {
        console.log('No frames found for the selected date');
        setConstructionImages([]);
        return;
      }

      const imageUrls = timestamps.map(frameTime => {
        const timestamp = typeof frameTime === 'string' ? frameTime : 
                         typeof frameTime === 'object' ? (frameTime.timestamp || frameTime.frameTime || frameTime.time) : 
                         frameTime;
        
        // Construct direct image URL
        const imageApiUrl = `${apiBaseUrl}/feed/recorded-image?${new URLSearchParams({
          feedId: user.feedID,
          frameTime: timestamp,
          sizeCode: 'x1080',
          apikey: apiKey
        }).toString()}`;
        
        console.log(`Image URL for timestamp ${timestamp}: ${imageApiUrl.substring(0, 100)}...`);
        
        return {
          url: imageApiUrl,
          timestamp: timestamp,
          formattedTime: new Date(timestamp).toLocaleTimeString()
        };
      });
      
      console.log(`Generated ${imageUrls.length} image URLs`);
      
      // Show success toast when images are loaded
      if (imageUrls.length > 0) {
        showToast(`Se cargaron ${imageUrls.length} imágenes correctamente`, 'success');
      } else {
        showToast('No se encontraron imágenes para esta fecha', 'info');
      }
      
      // Cache the results
      setCachedData(cacheKey, imageUrls);
      setConstructionImages(imageUrls);
    } catch (error) {
      console.error('Detalles del error de búsqueda:', error);
      setError(`Error al obtener imágenes: ${error.message}`);
      showToast(`Error: ${error.message}`, 'error');
      // Log to the console for debugging
      console.log('Error details:');
      console.log('User:', user);
      console.log('Selected date:', effectiveDate);
      console.log('API base URL:', apiBaseUrl);
    } finally {
      setImagesLoading(false);
    }
  }, [effectiveDate, user?.feedID, apiKey, apiBaseUrl]);

  // Load images on component mount
  useEffect(() => {
    if (!user?.feedID || !effectiveDate) {
      setError("Faltan datos requeridos: ID de feed o fecha");
      setImagesLoading(false);
      return;
    }
    fetchConstructionImages();
  }, [fetchConstructionImages, user, effectiveDate]);

  // Add scroll position tracking
  useEffect(() => {
    const handleScroll = () => {
      // Force component to re-render when scrolling for the scroll-to-top button
      setIsLoading(isLoading);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading]);

  // Update generateCaption to show toast notifications
  const generateCaption = async () => {
    if (!selectedImage) {
      setError("Por favor, seleccione una imagen para generar una descripción");
      showToast("Por favor, seleccione una imagen para generar una descripción", 'error');
      return;
    }
    
    if (!apiKeys.openaiKey) {
      setError("Servicio OpenAI no disponible. Verifique la conexión con el servidor.");
      showToast("Servicio OpenAI no disponible. Verifique la conexión con el servidor.", 'error');
      return;
    }

    try {
      setGeneratingCaption(true);
      setError(null);
      
      // Convert the image to base64 (we need to fetch it first since it's a URL)
      const imageResponse = await fetch(selectedImage.url);
      const imageBlob = await imageResponse.blob();
      const base64Image = await blobToBase64(imageBlob);
      
      // Make OpenAI API call
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKeys.openaiKey}`
        },
        body: JSON.stringify({
          model: "gpt-4o",
          messages: [
            {
              role: "user",
              content: [
                { type: "text", text: captionPrompt },
                {
                  type: "image_url",
                  image_url: {
                    url: base64Image
                  }
                }
              ]
            }
          ],
          max_tokens: 300
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error API OpenAI: ${errorData.error?.message || response.statusText}`);
      }
      
      const data = await response.json();
      const generatedCaption = data.choices[0]?.message?.content;
      
      if (!generatedCaption) {
        throw new Error("La API no devolvió ningún texto generado");
      }
      
      setAiCaption(generatedCaption);
      showToast("Descripción generada correctamente", 'success');
    } catch (error) {
      console.error('Error al generar la descripción:', error);
      setError(`No se pudo generar la descripción: ${error.message}`);
      showToast(`Error: ${error.message}`, 'error');
    } finally {
      setGeneratingCaption(false);
    }
  };

  // Helper function to convert Blob to base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Update generateAIImage to show toast notifications
  const generateAIImage = async () => {
    if (!selectedImage) {
      setError("Por favor, seleccione una imagen para generar un renderizado");
      showToast("Por favor, seleccione una imagen para generar un renderizado", 'error');
      return;
    }
    
    if (!apiKeys.dalleKey) {
      setError("Servicio DALL-E no disponible. Verifique la conexión con el servidor.");
      showToast("Servicio DALL-E no disponible. Verifique la conexión con el servidor.", 'error');
      return;
    }

    try {
      setGeneratingImage(true);
      setError(null);
      
      // Convert the image to base64 if not already done
      const imageResponse = await fetch(selectedImage.url);
      const imageBlob = await imageResponse.blob();
      const base64Image = await blobToBase64(imageBlob);
      
      // First get a description of the construction site using GPT-4 Vision
      const descriptionResponse = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKeys.dalleKey}`
        },
        body: JSON.stringify({
          model: "gpt-4o",
          messages: [
            {
              role: "user",
              content: [
                { 
                  type: "text", 
                  text: "Describe this construction site in detail. Focus on architectural features, intended purpose of the building, and overall design elements."
                },
                {
                  type: "image_url",
                  image_url: {
                    url: base64Image
                  }
                }
              ]
            }
          ],
          max_tokens: 300
        })
      });
      
      if (!descriptionResponse.ok) {
        const errorData = await descriptionResponse.json();
        throw new Error(`Error API OpenAI: ${errorData.error?.message || descriptionResponse.statusText}`);
      }
      
      const descriptionData = await descriptionResponse.json();
      const siteDescription = descriptionData.choices[0]?.message?.content;
      
      if (!siteDescription) {
        throw new Error("La API no devolvió ninguna descripción");
      }
      
      // Now use DALL-E to generate an image based on the description
      const customPrompt = `Create a photorealistic architectural visualization of ONLY the building shown in this construction site image, as if COMPLETED. Based on this description: ${siteDescription}

Key requirements:
1. Show ONLY THIS SPECIFIC BUILDING from the construction site - NO OTHER NEARBY BUILDINGS should be visible
2. Maintain the exact same architectural style, proportions, and distinctive features from the original construction image
3. Include 3-4 people near the building entrance to provide scale - business professionals and visitors
4. Add appropriate landscaping that complements the building's design with manicured greenery and walkways
5. The building must be shown as FULLY COMPLETED with the same materials suggested in the construction image but in finished form
6. Use golden-hour professional photography lighting to highlight architectural details
7. Include subtle details like signage matching the building's purpose, minimal street furniture, and 1-2 parked vehicles
8. Use a clear, unobstructed view with no distracting elements - focus entirely on showcasing this specific building
9. Image should have photorealistic quality with modern architectural photography aesthetics

The image must appear as a professional architectural visualization focusing EXCLUSIVELY on the building from the construction site photo, as if completed. The final result should look like a high-end marketing render that accurately represents what the specific building in the construction photo will look like when finished.`;
      
      // Determine which prompt to use
      const finalPrompt = usePredefinedImagePrompt 
        ? customPrompt  // Use the predefined detailed prompt
        : `${customImagePrompt} Based on this description: ${siteDescription}`;
      
      const dalleResponse = await fetch('https://api.openai.com/v1/images/generations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKeys.dalleKey}`
        },
        body: JSON.stringify({
          model: "dall-e-3",
          prompt: finalPrompt,
          n: 1,
          size: "1024x1024",
          quality: "hd",
          style: "natural"
        })
      });
      
      if (!dalleResponse.ok) {
        const errorData = await dalleResponse.json();
        throw new Error(`Error API DALL-E: ${errorData.error?.message || dalleResponse.statusText}`);
      }
      
      const imageData = await dalleResponse.json();
      const generatedImageUrl = imageData.data[0]?.url;
      
      if (!generatedImageUrl) {
        throw new Error("La API no devolvió ninguna imagen generada");
      }
      
      setGeneratedImage(generatedImageUrl);
      showToast("Renderizado generado correctamente", 'success');
    } catch (error) {
      console.error('Error al generar la imagen de IA:', error);
      setError(`No se pudo generar la imagen de IA: ${error.message}`);
      showToast(`Error: ${error.message}`, 'error');
    } finally {
      setGeneratingImage(false);
    }
  };

  // Update downloadContent to show toast notifications
  const downloadContent = async (type) => {
    try {
      if (type === 'caption' && aiCaption) {
        const blob = new Blob([aiCaption], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `descripcion-construccion-${new Date().toISOString().slice(0, 10)}.txt`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        showToast("Descripción descargada correctamente", 'success');
      } else if (type === 'image' && generatedImage) {
        // Show persistent toast that will remain until user returns from new tab
        setToast({ 
          visible: true, 
          message: "Imagen abierta en nueva pestaña. Guárdela con clic derecho o presión larga.", 
          type: 'info' 
        });
        
        // Open image directly in new tab
        window.open(generatedImage, '_blank');
      } else if (type === 'original' && selectedImage) {
        showToast("Preparando imagen original para descarga...", 'info');
        
        // For original images from our API, direct fetch should work
        try {
          // Format date and time for filename
          const timestamp = new Date(selectedImage.timestamp);
          const formattedDate = timestamp.toISOString().slice(0, 10);
          const formattedTime = timestamp.toTimeString().slice(0, 8).replace(/:/g, '-');
          const fileName = `obra-construccion-${formattedDate}-${formattedTime}.jpg`;
          
          // Fetch the image first
          const response = await fetch(selectedImage.url);
          if (!response.ok) {
            throw new Error("No se pudo descargar la imagen original. Inténtelo de nuevo.");
          }
          
          // Convert to blob
          const imageBlob = await response.blob();
          
          // Create object URL
          const url = window.URL.createObjectURL(imageBlob);
          
          // Create anchor and trigger download
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          
          // Clean up
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 100);
          
          showToast("Imagen original descargada correctamente", 'success');
        } catch (error) {
          console.error("Error downloading original image:", error);
          showToast(`Error: ${error.message}`, 'error');
        }
      }
    } catch (error) {
      console.error('Error al descargar contenido:', error);
      showToast(`Error: ${error.message}`, 'error');
    }
  };

  // Format date to Spanish
  const formatDateToSpanish = (date) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
  };

  // Function to test API connectivity
  const testApiConnectivity = async () => {
    try {
      console.log('Testing API connectivity...');
      const testUrl = `${apiBaseUrl}/health`;
      const response = await fetch(testUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        // Short timeout to quickly detect connectivity issues
        signal: AbortSignal.timeout(5000)
      });
      
      if (response.ok) {
        console.log('API connection successful');
        return true;
      } else {
        console.error('API connection failed with status:', response.status);
        return false;
      }
    } catch (error) {
      console.error('API connectivity test failed:', error);
      return false;
    }
  };

  // Add a retry function for image loading
  const retryLoadingImages = async () => {
    if (await testApiConnectivity()) {
      console.log('Retrying image loading...');
      fetchConstructionImages();
    } else {
      setError("No se pudo conectar con el servidor de API. Verifique su conexión a Internet.");
    }
  };

  // Add image zoom functionality
  const toggleImageZoom = () => {
    setImageZoom(!imageZoom);
  };

  // Add a scroll-to-top button
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Add event listener for window focus to clear persistent toasts
  useEffect(() => {
    const handleWindowFocus = () => {
      // Clear any persistent toasts when returning to this tab
      if (toast.visible) {
        setToast({ ...toast, visible: false });
      }
    };
    
    window.addEventListener('focus', handleWindowFocus);
    
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
    };
  }, [toast]);

  return (
    <div className="flex flex-col w-full mx-auto px-2 py-2 sm:px-4 sm:py-4 gap-3 sm:gap-4">
      {/* Toast notification */}
      {toast.visible && (
        <div className={`fixed top-4 right-4 z-50 flex items-center p-3 rounded-lg shadow-lg animate-slideIn max-w-[calc(100%-2rem)] ${
          toast.type === 'success' ? 'bg-green-100 text-green-800' : 
          toast.type === 'error' ? 'bg-red-100 text-red-800' : 
          'bg-blue-100 text-blue-800'
        }`}>
          {toast.type === 'success' ? (
            <CheckCircle className="h-4 w-4 flex-shrink-0 mr-2" />
          ) : toast.type === 'error' ? (
            <AlertCircle className="h-4 w-4 flex-shrink-0 mr-2" />
          ) : (
            <MessageSquare className="h-4 w-4 flex-shrink-0 mr-2" />
          )}
          <span className="text-sm mr-2 flex-1">{toast.message}</span>
          <button 
            onClick={() => setToast({ ...toast, visible: false })}
            className="text-gray-500 hover:text-gray-700 flex-shrink-0"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      )}
      
      {/* Image zoom modal */}
      {imageZoom && selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center p-2"
          onClick={toggleImageZoom}
        >
          <div className="relative max-w-full max-h-[90vh]">
            <img 
              src={selectedImage.url} 
              alt="Vista ampliada" 
              className="max-w-full max-h-[90vh] object-contain"
            />
            <button 
              className="absolute top-2 right-2 bg-white rounded-full p-1 shadow-lg"
              onClick={toggleImageZoom}
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
      
      {/* Header section */}
      <div className="bg-gradient-to-r from-blue-800 to-cyan-600 p-4 sm:p-6 rounded-xl shadow-lg text-white">
        <h1 className="text-2xl sm:text-3xl font-bold mb-2">Creador de Contenido IA</h1>
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <p className="text-base sm:text-lg opacity-90 mb-2 sm:mb-0">
            Convierte fotos de obra en material para marketing
          </p>
          <div className="flex items-center bg-white/10 px-3 py-1 rounded-full text-xs font-medium self-start">
            <CheckCircle className="h-3 w-3 mr-1" />
            Servidor: Activo
          </div>
        </div>
      </div>
      
      {/* Date Picker Section */}
      <div className="bg-white rounded-xl shadow-md p-4">
        <div className="flex flex-col space-y-3">
          <div className="flex items-center">
            <Calendar className="mr-2 h-5 w-5 text-blue-600" />
            <h2 className="text-lg font-semibold">Seleccionar Fecha</h2>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <button 
              onClick={() => navigateDay(-1)}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors text-sm flex items-center justify-center"
              aria-label="Día anterior"
            >
              &laquo; Día anterior
            </button>
            
            <div className="relative">
              <input
                type="date"
                value={getDateString(currentDate)}
                onChange={handleDateChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm"
              />
            </div>
            
            <button 
              onClick={() => navigateDay(1)}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200 rounded-md transition-colors text-sm flex items-center justify-center"
              aria-label="Día siguiente"
              disabled={new Date(currentDate).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)}
            >
              Día siguiente &raquo;
            </button>
          </div>
        </div>
        
        <div className="mt-2 text-center">
          <p className="text-gray-600 font-medium text-sm">
            {formatDateToSpanish(currentDate)}
          </p>
        </div>
      </div>
      
      {/* Main content area */}
      <div className="grid grid-cols-1 gap-3 sm:gap-4">
        {/* Source images */}
        <div className="bg-white rounded-xl shadow-md p-4 transition-all duration-300">
          <h2 className="text-lg font-semibold mb-3 flex items-center">
            <Camera className="mr-2 h-5 w-5 text-blue-600" />
            Imágenes de la Obra
          </h2>
          
          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg text-sm">
              <p className="font-semibold mb-1">Error:</p>
              <p>{error}</p>
              <button 
                onClick={retryLoadingImages}
                className="mt-2 px-3 py-1 bg-blue-500 text-white text-xs rounded hover:bg-blue-600 transition-colors"
              >
                <RefreshCw className="w-3 h-3 mr-1 inline" />
                Reintentar
              </button>
            </div>
          )}
          
          {imagesLoading ? (
            <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg">
              <div className="relative w-10 h-10">
                <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full"></div>
                <div className="absolute top-0 left-0 w-full h-full border-4 border-transparent border-t-blue-500 rounded-full animate-spin"></div>
              </div>
              <p className="text-gray-600 mt-3 text-sm">Cargando imágenes...</p>
            </div>
          ) : constructionImages.length === 0 ? (
            <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg">
              <Camera className="w-10 h-10 text-gray-400 mb-2" />
              <h3 className="text-base font-semibold text-gray-600 mb-1">No hay imágenes disponibles</h3>
              <p className="text-gray-500 text-center text-sm mb-2">
                No se encontraron imágenes para esta fecha
              </p>
              <button 
                onClick={retryLoadingImages}
                className="px-3 py-1 bg-blue-500 text-white rounded text-sm hover:bg-blue-600 transition-colors flex items-center"
              >
                <RefreshCw className="w-3 h-3 mr-1" />
                Actualizar
              </button>
            </div>
          ) : (
            <>
              <p className="mb-3 text-gray-600 text-sm">
                {constructionImages.length} imágenes disponibles. Seleccione una para generar contenido.
              </p>
              
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-3 max-h-[300px] overflow-y-auto p-1">
                {constructionImages.map((image, index) => (
                  <div 
                    key={index} 
                    className={`relative cursor-pointer rounded-lg overflow-hidden border-2 transition-all duration-300 ${
                      selectedImage === image ? 'border-blue-500 shadow-md' : 'border-transparent hover:border-gray-300'
                    }`}
                    onClick={() => setSelectedImage(image)}
                  >
                    <div className="absolute top-1 left-1 bg-black bg-opacity-60 text-white text-xs p-1 rounded z-10">
                      {image.formattedTime}
                    </div>
                    <div className="aspect-video">
                      <img 
                        src={image.url} 
                        alt={`Construcción ${index + 1}`}
                        className="w-full h-full object-cover transition-all duration-500"
                        loading="lazy"
                        onError={(e) => {
                          console.error(`Error al cargar imagen: ${image.url}`);
                          // Try one more time with a timestamp to prevent caching issues
                          const retryUrl = `${image.url}&retry=${Date.now()}`;
                          console.log(`Retrying with: ${retryUrl.substring(0, 100)}...`);
                          
                          // Only retry once to prevent infinite loop
                          if (!e.target.dataset.retried) {
                            e.target.dataset.retried = 'true';
                            e.target.src = retryUrl;
                          } else {
                            e.target.src = fallbackImageUrl;
                            e.target.classList.remove('object-cover');
                            e.target.classList.add('object-contain');
                          }
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          
          {selectedImage && (
            <div className="mt-3 animate-fadeIn">
              <h3 className="text-sm font-medium mb-1">Imagen Seleccionada</h3>
              <div 
                className="rounded-lg overflow-hidden border border-gray-200 shadow-sm transition-all duration-300 hover:shadow cursor-zoom-in relative"
                onClick={toggleImageZoom}
              >
                <img 
                  src={selectedImage.url} 
                  alt="Obra de construcción seleccionada" 
                  className="w-full object-contain max-h-[200px] transition-all duration-500"
                  onError={(e) => {
                    console.error(`Error al cargar imagen seleccionada: ${selectedImage.url}`);
                    // Try one more time with a timestamp to prevent caching issues
                    const retryUrl = `${selectedImage.url}&retry=${Date.now()}`;
                    
                    // Only retry once to prevent infinite loop
                    if (!e.target.dataset.retried) {
                      e.target.dataset.retried = 'true';
                      e.target.src = retryUrl;
                    } else {
                      e.target.src = fallbackImageUrl;
                    }
                  }}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent image zoom
                    downloadContent('original');
                  }}
                  className="absolute bottom-2 right-2 p-1.5 bg-green-100 text-green-700 rounded-full hover:bg-green-200 transition-all duration-300"
                  title="Descargar Imagen Original"
                >
                  <Download className="h-3 w-3" />
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-1 text-center">Toque para ampliar o use el botón para descargar</p>
            </div>
          )}
        </div>
        
        {/* AI Generation */}
        <div className="bg-white rounded-xl shadow-md p-4 transition-all duration-300">
          <h2 className="text-lg font-semibold mb-3 flex items-center">
            <Wand2 className="mr-2 h-5 w-5 text-purple-600" />
            Generación con IA
          </h2>
          
          {!selectedImage ? (
            <div className="flex flex-col items-center justify-center p-4 bg-gray-50 rounded-lg text-gray-500">
              <ImageIcon className="w-10 h-10 text-gray-400 mb-2" />
              <p className="text-sm text-center">Seleccione una imagen para comenzar a generar contenido</p>
            </div>
          ) : (
            <div className="space-y-4">
              {/* Caption generation section */}
              <div className="border border-gray-200 rounded-lg p-3">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-base font-medium flex items-center">
                    <MessageSquare className="mr-1 h-4 w-4 text-blue-500" />
                    Descripción
                  </h3>
                  
                  <button
                    onClick={generateCaption}
                    disabled={generatingCaption || !apiKeys.openaiKey}
                    className={`px-3 py-1.5 rounded-md flex items-center text-sm transition-all duration-300 ${
                      generatingCaption || !apiKeys.openaiKey
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                  >
                    {generatingCaption ? (
                      <>
                        <div className="relative h-3 w-3 mr-1">
                          <div className="absolute top-0 left-0 w-full h-full border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        </div>
                        Generando...
                      </>
                    ) : (
                      <>
                        <Wand2 className="h-3 w-3 mr-1" />
                        Generar
                      </>
                    )}
                  </button>
                </div>
                
                <div className="mb-2">
                  <div className="flex justify-between items-center mb-1">
                    <label className="block text-xs text-gray-500">
                      Tipo de instrucciones:
                    </label>
                    <div className="flex items-center space-x-1 text-xs">
                      <span className={`px-2 py-0.5 rounded cursor-pointer ${usePredefinedCaptionPrompt ? 'bg-blue-100 text-blue-700 font-medium' : 'bg-gray-100 text-gray-600'}`} 
                        onClick={() => setUsePredefinedCaptionPrompt(true)}
                        title="Utiliza instrucciones optimizadas para generar descripciones de marketing">
                        Predefinido
                      </span>
                      <span className={`px-2 py-0.5 rounded cursor-pointer ${!usePredefinedCaptionPrompt ? 'bg-blue-100 text-blue-700 font-medium' : 'bg-gray-100 text-gray-600'}`}
                        onClick={() => setUsePredefinedCaptionPrompt(false)}
                        title="Crea tus propias instrucciones para la generación de descripciones">
                        Personalizado
                      </span>
                    </div>
                  </div>
                  
                  {usePredefinedCaptionPrompt ? (
                    <div className="p-2 bg-blue-50 border border-blue-100 rounded-md text-xs text-gray-700">
                      <p className="text-blue-600 font-medium flex items-center">
                        <Info className="h-3 w-3 mr-1" />
                        Instrucciones optimizadas para descripciones de marketing
                      </p>
                    </div>
                  ) : (
                    <textarea
                      value={customCaptionPrompt}
                      onChange={(e) => setCustomCaptionPrompt(e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm"
                      rows={2}
                      placeholder="Escriba sus instrucciones para la IA..."
                    />
                  )}
                </div>
                
                {aiCaption ? (
                  <div className="relative">
                    <div className="p-2 bg-gray-50 rounded-md text-gray-800 text-sm max-h-[150px] overflow-y-auto">
                      {aiCaption}
                    </div>
                    <button
                      onClick={() => downloadContent('caption')}
                      className="absolute bottom-2 right-2 p-1.5 bg-blue-100 text-blue-700 rounded-full hover:bg-blue-200 transition-all duration-300"
                      title="Descargar Descripción"
                    >
                      <Download className="h-3 w-3" />
                    </button>
                  </div>
                ) : (
                  <div className="p-2 bg-gray-50 rounded-md text-gray-500 text-sm min-h-[80px] flex items-center justify-center">
                    {!apiKeys.openaiKey ? 
                      "Servicio de texto no disponible" :
                      "Haga clic en 'Generar' para crear una descripción"
                    }
                  </div>
                )}
              </div>
              
              {/* AI Image generation section */}
              <div className="border border-gray-200 rounded-lg p-3">
                <div className="flex justify-between items-center mb-2">
                  <h3 className="text-base font-medium flex items-center">
                    <ImageIcon className="mr-1 h-4 w-4 text-purple-500" />
                    Renderizado
                  </h3>
                  
                  <button
                    onClick={generateAIImage}
                    disabled={generatingImage || !apiKeys.dalleKey}
                    className={`px-3 py-1.5 rounded-md flex items-center text-sm transition-all duration-300 ${
                      generatingImage || !apiKeys.dalleKey
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-purple-600 text-white hover:bg-purple-700'
                    }`}
                  >
                    {generatingImage ? (
                      <>
                        <div className="relative h-3 w-3 mr-1">
                          <div className="absolute top-0 left-0 w-full h-full border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                        </div>
                        Renderizando...
                      </>
                    ) : (
                      <>
                        <Wand2 className="h-3 w-3 mr-1" />
                        Generar
                      </>
                    )}
                  </button>
                </div>
                
                <div className="mb-2">
                  <div className="flex justify-between items-center mb-1">
                    <label className="block text-xs text-gray-500">
                      Tipo de instrucciones:
                    </label>
                    <div className="flex items-center space-x-1 text-xs">
                      <span className={`px-2 py-0.5 rounded cursor-pointer ${usePredefinedImagePrompt ? 'bg-purple-100 text-purple-700 font-medium' : 'bg-gray-100 text-gray-600'}`} 
                        onClick={() => setUsePredefinedImagePrompt(true)}
                        title="Utiliza instrucciones optimizadas para generar renderizados arquitectónicos">
                        Predefinido
                      </span>
                      <span className={`px-2 py-0.5 rounded cursor-pointer ${!usePredefinedImagePrompt ? 'bg-purple-100 text-purple-700 font-medium' : 'bg-gray-100 text-gray-600'}`}
                        onClick={() => setUsePredefinedImagePrompt(false)}
                        title="Crea tus propias instrucciones para la generación de imágenes">
                        Personalizado
                      </span>
                    </div>
                  </div>
                  
                  {usePredefinedImagePrompt ? (
                    <div className="p-2 bg-purple-50 border border-purple-100 rounded-md text-xs text-gray-700">
                      <p className="text-purple-600 font-medium flex items-center">
                        <Info className="h-3 w-3 mr-1" />
                        Instrucciones optimizadas para renderizados arquitectónicos
                      </p>
                    </div>
                  ) : (
                    <textarea
                      value={customImagePrompt}
                      onChange={(e) => setCustomImagePrompt(e.target.value)}
                      className="w-full p-2 border border-gray-200 rounded-md focus:ring-blue-500 focus:border-blue-500 text-sm"
                      rows={2}
                      placeholder="Escriba sus instrucciones para la IA..."
                    />
                  )}
                </div>
                
                {generatedImage ? (
                  <div className="relative">
                    <div className="rounded-md overflow-hidden bg-gray-100">
                      <img 
                        src={generatedImage} 
                        alt="Renderizado arquitectónico generado por IA" 
                        className="w-full object-contain max-h-[200px]"
                      />
                    </div>
                    <button
                      onClick={() => downloadContent('image')}
                      className="absolute bottom-2 right-2 px-2 py-1 bg-purple-100 text-purple-700 rounded-md hover:bg-purple-200 text-xs font-medium flex items-center"
                      title="Ver Imagen Completa"
                    >
                      <Download className="h-3 w-3 mr-1" />
                      Ver completa
                    </button>
                    <div className="mt-2 p-2 bg-amber-50 border border-amber-200 rounded-md text-xs text-amber-800">
                      <p className="flex items-start">
                        <AlertCircle className="h-3.5 w-3.5 mr-1 flex-shrink-0 mt-0.5" />
                        <span>
                          Al hacer clic en descargar, la imagen se abrirá en una nueva pestaña. Para guardarla:
                          <ul className="mt-1 ml-4 list-disc text-amber-700">
                            <li>En computadoras: Clic derecho en la imagen y seleccione "Guardar imagen como"</li>
                            <li>En móviles: Mantenga presionada la imagen y seleccione "Descargar imagen"</li>
                          </ul>
                        </span>
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="p-2 bg-gray-50 rounded-md text-gray-500 text-sm min-h-[80px] flex items-center justify-center">
                    {!apiKeys.dalleKey ? 
                      "Servicio de imagen no disponible" :
                      "Haga clic en 'Generar' para crear un renderizado"
                    }
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Information section */}
      <div className="bg-gray-50 p-4 rounded-xl border border-gray-200 mt-3 sm:mt-4">
        <h3 className="text-base font-semibold mb-2">Cómo Funciona</h3>
        <ol className="list-decimal list-inside space-y-1 text-gray-700 text-sm">
          <li>Seleccione una fecha para ver las fotos disponibles</li>
          <li>Elija una foto de la obra</li>
          <li>Seleccione entre instrucciones predefinidas o personalizadas</li>
          <li>Genere una descripción para marketing</li>
          <li>Genere un renderizado del proyecto terminado</li>
          <li>Guarde el contenido para usar en sus materiales</li>
        </ol>
      </div>

      {/* Scroll-to-top button */}
      <div className="fixed bottom-16 sm:bottom-4 right-4 z-40">
        <button
          onClick={scrollToTop}
          className={`p-2 bg-blue-600 text-white rounded-full shadow-lg transition-all duration-300 hover:bg-blue-700 ${
            window.scrollY > 300 ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10 pointer-events-none'
          }`}
          aria-label="Volver arriba"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ConstructionAICaptionMaker;
