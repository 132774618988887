import React, { useState, useEffect, useCallback } from 'react';
import { Download, Camera } from 'lucide-react';

const ImageGallery = ({ selectedDate, user }) => {
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiKey = "HWN2M2mcbZoyFDTfpaskQV9TBDk0Ir8bu4XWDxJQiBE";
  const apiBaseUrl = "https://lisual-admin-backend-985418867286.us-central1.run.app/api";
  
  // Fallback image as base64 data URL - light gray with error text
  const fallbackImageUrl = 'data:image/svg+xml;base64,' + btoa(`
    <svg width="300" height="200" xmlns="http://www.w3.org/2000/svg">
      <rect width="100%" height="100%" fill="#f3f4f6"/>
      <text x="50%" y="50%" font-family="Arial" font-size="16" fill="#6b7280" text-anchor="middle">
        Error al cargar imagen
      </text>
    </svg>
  `);

  // Cache configuration
  const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
  const cache = new Map();

  const formatDate = (date) => {
    return date.toISOString().replace(/\.\d{3}Z$/, 'Z');
  };

  const handleApiError = (status) => {
    const errorMessages = {
      400: "Parámetros de solicitud inválidos. Por favor, compruebe el rango de fechas.",
      401: "Acceso no autorizado. Por favor, compruebe su clave API.",
      403: "Permisos insuficientes para acceder a este feed.",
      404: "Feed o imágenes no encontradas.",
      500: "Error del servidor. Por favor, inténtelo de nuevo más tarde.",
      503: "Servicio temporalmente no disponible.",
      504: "Tiempo de espera de la solicitud agotado. Por favor, inténtelo de nuevo."
    };
    return errorMessages[status] || `Error al cargar imágenes: ${status}`;
  };

  const getCachedData = (key) => {
    const cached = cache.get(key);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return cached.data;
    }
    cache.delete(key);
    return null;
  };

  const setCachedData = (key, data) => {
    cache.set(key, {
      data,
      timestamp: Date.now()
    });
  };

  const parseTimestamps = (data) => {
    if (!data) return [];
    
    // If it's already an array of timestamps
    if (Array.isArray(data)) {
      return data;
    }
    
    // Handle nested frames structure: { frames: { Frames: [...] } }
    if (data.frames && data.frames.Frames && Array.isArray(data.frames.Frames)) {
      return data.frames.Frames;
    }
    
    // If it's an object with frames property
    if (data.frames && Array.isArray(data.frames)) {
      return data.frames;
    }
    
    // If it's an object with timestamps
    if (data.timestamps && Array.isArray(data.timestamps)) {
      return data.timestamps;
    }

    // If it's an object with Frames property
    if (data.Frames && Array.isArray(data.Frames)) {
      return data.Frames;
    }

    // If it's an object with a different structure, try to extract timestamps
    if (typeof data === 'object') {
      // Recursively search for arrays in nested objects
      const findTimestamps = (obj) => {
        for (const key in obj) {
          if (Array.isArray(obj[key]) && obj[key].length > 0 && typeof obj[key][0] === 'string') {
            return obj[key];
          }
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            const result = findTimestamps(obj[key]);
            if (result) return result;
          }
        }
        return null;
      };
      
      const timestamps = findTimestamps(data);
      if (timestamps) return timestamps;
    }

    console.error('Unexpected API response format:', data);
    return [];
  };

  const fetchImages = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Create start and end of day in UTC
      const start = new Date(selectedDate);
      start.setUTCHours(0, 0, 0, 0);
      const end = new Date(selectedDate);
      end.setUTCHours(23, 59, 59, 999);
      
      // Check cache first
      const cacheKey = `${user.feedID}-${start.toISOString()}`;
      const cachedImages = getCachedData(cacheKey);
      if (cachedImages) {
        console.log('Using cached images:', cachedImages.length);
        setImages(cachedImages);
        setIsLoading(false);
        return;
      }

      // Construct URL with proper parameters
      const params = new URLSearchParams({
        feedId: user.feedID,
        startTime: formatDate(start),
        endTime: formatDate(end),
        apikey: apiKey,
        interval: '3600' // 1 hour interval in seconds
      });
      
      const apiUrl = `${apiBaseUrl}/feed/frames?${params.toString()}`;
      console.log('Fetching frames from:', apiUrl);
      
      const response = await fetch(apiUrl);
      if (!response.ok) {
        const errorText = await response.text();
        console.error('API Error Response:', errorText);
        throw new Error(handleApiError(response.status));
      }
      
      const data = await response.json();
      console.log('API Response:', data);

      // Parse timestamps from the response
      const timestamps = parseTimestamps(data);
      console.log('Parsed timestamps:', timestamps);
      
      if (timestamps.length === 0) {
        console.log('No frames found for the selected date');
        setImages([]);
        return;
      }

      const imageUrls = timestamps.map(frameTime => {
        const timestamp = typeof frameTime === 'string' ? frameTime : 
                         typeof frameTime === 'object' ? (frameTime.timestamp || frameTime.frameTime || frameTime.time) : 
                         frameTime;
                         
        // Construct image URL with all required parameters
        const imageUrl = `${apiBaseUrl}/feed/recorded-image?${new URLSearchParams({
          feedId: user.feedID,
          frameTime: timestamp,
          sizeCode: 'x1080',
          apikey: apiKey
        }).toString()}`;

        console.log(`Image URL for timestamp ${timestamp}: ${imageUrl}`);
        
        return {
          url: imageUrl,
          timestamp: timestamp,
          formattedTime: new Date(timestamp).toLocaleTimeString() // Add formatted time for display
        };
      });
      
      console.log(`Generated ${imageUrls.length} image URLs`);
      
      // Cache the results
      setCachedData(cacheKey, imageUrls);
      setImages(imageUrls);
    } catch (error) {
      console.error('Fetch error details:', error);
      setError(error.message);
      // Add additional debug information
      console.log('Error details:');
      console.log('User:', user);
      console.log('Selected date:', selectedDate);
      console.log('API base URL:', apiBaseUrl);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDate, user?.feedID, apiKey, apiBaseUrl]);

  useEffect(() => {
    if (!user?.feedID || !selectedDate) {
      setError("Missing required feed ID or date");
      setIsLoading(false);
      return;
    }
    fetchImages();
  }, [fetchImages, user, selectedDate]);

  const downloadImage = async (imageUrl, timestamp) => {
    try {
      console.log(`Downloading image: ${imageUrl}`);
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(handleApiError(response.status));
      }
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `image-${new Date(timestamp).toISOString()}.jpg`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      console.log('Image downloaded successfully');
    } catch (error) {
      console.error('Error downloading image:', error);
      setError(`Error downloading image: ${error.message}`);
    }
  };

  const formatDateToSpanish = (date) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <div className="text-red-500 text-center">
          <p className="font-semibold mb-2">Error</p>
          <p>{error}</p>
          <p className="mt-2 text-sm">Verifique la configuración de la API y su conexión a internet.</p>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mb-4"></div>
        <p className="text-gray-600">Cargando imágenes...</p>
      </div>
    );
  }

  if (images.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center p-8 bg-gray-50 rounded-lg">
        <Camera className="w-16 h-16 text-gray-400 mb-4" />
        <h3 className="text-xl font-semibold text-gray-600 mb-2">No hay fotos disponibles</h3>
        <p className="text-gray-500 text-center">
          No se encontraron fotos para el día {formatDateToSpanish(selectedDate)}
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        Se han encontrado {images.length} imágenes para el día {formatDateToSpanish(selectedDate)}
      </div>
      <div className="mb-4 font-bold">Atención! Espere unos segundos a que las imágenes carguen.</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {images.map((image, index) => (
          <div key={index} className="relative group bg-gray-100 rounded-xl overflow-hidden">
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
              <Download 
                className="text-white w-12 h-12 cursor-pointer" 
                onClick={() => downloadImage(image.url, image.timestamp)}
              />
            </div>
            <div className="absolute top-2 left-2 bg-white bg-opacity-70 p-1 rounded text-xs z-20">
              {image.formattedTime || new Date(image.timestamp).toLocaleTimeString()}
            </div>
            <div className="relative aspect-video">
              <img 
                src={image.url} 
                alt={`Image ${index + 1}`} 
                className="absolute inset-0 w-full h-full object-cover"
                loading="lazy"
                onError={(e) => {
                  console.error(`Failed to load image: ${image.url}`);
                  e.target.src = fallbackImageUrl;
                  e.target.classList.remove('object-cover');
                  e.target.classList.add('object-contain');
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;