import React, { useState, useEffect, useCallback } from "react";
import {
  Bell,
  Menu,
  Camera,
  Clock,
  Archive,
  Home,
  Users,
  Settings,
  CreditCard,
  HelpCircle,
  BookUser,
  Book,
  PresentationIcon,
  DoorOpen,
  CloudSunIcon,
  Video,
  ChevronLeft,
  ChevronRight,
  Gift,
  GraduationCap,
  MessageCircle,
} from "lucide-react";
import Calendar from "./Calendar.js";
import "./App.css";
import logo from "./media/lisualpro.png";
import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase-config";
import ImageGallery from "./ImageGallery.js";
import UserProfileCard from "./Profile.js";
import NotificationForm from "./NotificationForm.js";
import Instagram from "./Instagram.js";
import Timelapse from "./Timelapse.js";
import FAQTab from "./faq.js";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import WorkInProgress from "./WorkInProgress.js";
import CommunityPreview from "./communityPreview.js";
import WeatherComponent from "./weather.js";
import { FloatingWhatsAppButton } from "./whatsapp.js";
import Dashboard from './Dashboard';

const ConstructionDashboard = () => {
  const { identifier } = useParams();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeTab, setActiveTab] = useState("Inicio");
  const [objects, setObjects] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [userdata, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [dayTime, setDayTime] = useState("");

  const livecam = `//www.teleport.io/api/v2/player?feedid=${userdata.feedID}&playmode=liveimageloop&imageplayspeed=1fps&playframeskipinterval=day&playframecount=600`;
  const timelapse = `https://www.teleport.io/api/v2/video-get?feedid=${userdata.feedID}&videoquery=all-time&videotype=live`;
  const location = useLocation();
  const word = location.state?.keyword || "No word provided";
  const formatDateToSpanish = (date) => {
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

    return `${days[date.getDay()]}, ${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`;
  };

  const fetchPost = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "usuarios"));
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setObjects(newData);

      const userData = newData.find(
        (element) => element.identifier === identifier
      );
      if (userData) {
        console.log("Setting userData:", userData);
        setUserData(userData);
      } else {
        // If no user is found, redirect to landing page
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    fetchPost();
    const currentTime = new Date();
    if (currentTime.getHours() < 12) {
      setDayTime("buen día  👋🏼");
    } else if (currentTime.getHours() >= 12 && currentTime.getHours() < 18) {
      setDayTime("buenas tardes  👋🏼");
    } else {
      setDayTime("buenas noches  👋🏼");
    }
  }, [identifier]); // Add identifier to dependency array

  const handleIframeLoad = useCallback(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Inicio":
        return (
          <div className="flex flex-col space-y-4">
            <h2 className="text-xl font-semibold mb-4">
              Hola, {dayTime}!
            </h2>
            
            {/* Live Camera Section - Moved to top */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-5xl mx-auto w-full">
              <div className="p-4 bg-blue-600 flex justify-center items-center">
                <h3 className="text-xl font-bold text-white">
                  Cámara en Vivo
                </h3>
              </div>
              <div className="relative aspect-video">
                <iframe
                  src={livecam}
                  title="Live Image"
                  className="absolute inset-0 w-full h-full"
                  onLoad={handleIframeLoad}
                  allowFullScreen
                />
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                  </div>
                )}
              </div>
            </div>

            {/* Feed Info Cards Section */}
            <div className="bg-gray-50 rounded-lg p-4">
              {userdata && userdata.feedID && (
                <Dashboard user={userdata} />
              )}
            </div>
          </div>
        );
      case "Comunidad":
        return (
          <div>
            <CommunityPreview user={userdata} />
          </div>
        );
      case "Archivo Fotografico":
        return (
          <div>
            <div className="space-y-4">
              <h2 className="text-xl font-semibold">Archivo Fotografico</h2>
              <Calendar
                mode="single"
                selected={selectedDate}
                onDateSelect={setSelectedDate}
                user={userdata}
                className="w-full max-w-sm mx-auto rounded-md border"
              />
              <div className="bg-white rounded-lg shadow p-4">
                <h3 className="text-lg font-semibold mb-2">
                  Fotos del {formatDateToSpanish(selectedDate)}
                </h3>
                <ImageGallery selectedDate={selectedDate} user={userdata} />
              </div>
            </div>
          </div>
        );
      case "Timelapse":
        return (
          <div className="space-y-4">
            <Timelapse videoSrc={timelapse} userdata={userdata} />
          </div>
        );
      case "Whatsapp + Correo":
        return (
          <div className="space-y-4">
            <NotificationForm id={userdata} />
          </div>
        );
      case "Redes Sociales":
        return (
          <div className="space-y-4">
            <Instagram user={userdata} selectedDate={selectedDate} />
          </div>
        );
      case "Ayuda":
        return (
          <div className="space-y-4">
            <FAQTab />
          </div>
        );
      case "Clima":
        return (
          <div className="space-y-4">
            <WeatherComponent user={userdata} />
          </div>
        );
      case "Referidos":
      case "Quiero una mentoria":
        return (
          <div className="space-y-4">
            <WorkInProgress />
          </div>
        );
      default:
        return (
          <div className="space-y-4">
            <h2 className="text-xl font-semibold">{activeTab}</h2>
            <div className="bg-white rounded-lg shadow p-4">
              <img
                src="/api/placeholder/800/400"
                alt="Seccion en progreso!"
                className="w-full h-48 object-cover rounded"
              />
            </div>
          </div>
        );
    }
  };

  const menuItems = [
    { name: "Inicio", icon: <Home size={24} /> },
    { name: "Timelapse", icon: <Video size={24} /> },
    { name: "Archivo Fotografico", icon: <Archive size={24} /> },
    { name: "Whatsapp + Correo", icon: <BookUser size={24} /> },
    { name: "Redes Sociales", icon: <PresentationIcon size={24} /> },
    { name: "Comunidad", icon: <Users size={24} /> },
    { name: "Clima", icon: <CloudSunIcon size={24} /> },
    { name: "Ayuda", icon: <HelpCircle size={24} /> },
    { name: "Referidos", icon: <Gift size={24} /> },
    { name: "Quiero una mentoria", icon: <GraduationCap size={24} /> },
  ];

  return (
    <div className="flex h-screen overflow-hidden bg-gray-100">
      {/* Desktop Sidebar */}
      <aside
        className={`
          hidden md:block fixed inset-y-0 left-0 z-30 transition-all duration-300 ease-in-out
          bg-gradient-to-br from-blue-200 via-blue-100 to-white border-r border-blue-200 shadow-lg overflow-y-auto
          ${isSidebarOpen ? "w-64" : "w-0"}
        `}
      >
        <div className="p-6 bg-blue-100">
          <img src={logo} alt="Logo" className="h-16 w-auto mx-auto mb-6" />
        </div>
        <nav className="px-4">
          <ul className="space-y-3">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={`
            transition-all duration-200
            ${activeTab === item.name
                    ? "bg-white text-blue-800 shadow-md border-2 border-blue-300"
                    : "bg-white/80 hover:bg-white text-gray-700 hover:text-blue-700 border border-blue-100"
                  }
            rounded-xl cursor-pointer backdrop-blur-sm
          `}
                onClick={() => {
                  setActiveTab(item.name);
                  if (window.innerWidth < 768) {
                    setIsSidebarOpen(false);
                  }
                }}
              >
                <div className="flex items-center p-3">
                  <span className={`${activeTab === item.name ? "text-blue-600" : "text-gray-500"}`}>
                    {item.icon}
                  </span>
                  <span className="ml-3 font-medium">{item.name}</span>
                </div>
              </li>
            ))}
            <li className="pt-4 mt-4 border-t border-blue-200">
              <button
                onClick={() => navigate("/")}
                className="w-full flex items-center p-3 bg-white/80 text-red-600 hover:bg-white rounded-xl transition-colors border border-blue-100"
              >
                <DoorOpen />
                <span className="ml-3 font-medium">Salir</span>
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 z-30 bg-white border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-between px-4 py-3">
          <img src={logo} alt="Logo" className="h-8 w-auto" />
          <button
            onClick={() => setIsSidebarOpen(true)}
            className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
          >
            <Menu size={24} className="text-gray-600" />
          </button>
        </div>
      </div>

      {/* Mobile Sidebar Modal */}
      {isSidebarOpen && (
        <div className="md:hidden fixed inset-0 z-50">
          <div 
            className="absolute inset-0 bg-black/30 backdrop-blur-sm transition-opacity duration-300"
            onClick={() => setIsSidebarOpen(false)}
          />
          <div className="absolute inset-y-0 left-0 w-[85%] max-w-sm bg-gradient-to-r from-blue-100 to-white shadow-xl">
            <div className="flex flex-col h-full">
              <div className="p-6 border-b border-blue-100">
                <div className="flex items-center justify-between">
                  <img src={logo} alt="Logo" className="h-8 w-auto" />
                  <button
                    onClick={() => setIsSidebarOpen(false)}
                    className="p-2 rounded-lg hover:bg-blue-50 transition-colors"
                  >
                    <ChevronLeft size={24} className="text-gray-600" />
                  </button>
                </div>
              </div>
              
              <nav className="flex-1 overflow-y-auto p-4 space-y-2">
                {menuItems.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => {
                      setActiveTab(item.name);
                      setIsSidebarOpen(false);
                    }}
                    className={`
                      w-full flex items-center p-4 rounded-xl transition-all duration-200
                      ${activeTab === item.name 
                        ? "bg-white text-blue-600 shadow-md border border-blue-100" 
                        : "hover:bg-white/80 text-gray-600"
                      }
                    `}
                  >
                    {React.cloneElement(item.icon, { size: 20, className: "flex-shrink-0" })}
                    <span className="ml-3 font-medium truncate">{item.name}</span>
                    {activeTab === item.name && (
                      <span className="ml-auto w-2 h-2 bg-blue-500 rounded-full" />
                    )}
                  </button>
                ))}
                
                <button
                  onClick={() => navigate("/")}
                  className="w-full flex items-center p-4 mt-6 text-red-600 border-t border-blue-100"
                >
                  <DoorOpen size={20} className="flex-shrink-0" />
                  <span className="ml-3 font-medium">Salir</span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}

      {/* Main content wrapper */}
      <div className={`flex-1 flex flex-col ${isSidebarOpen ? "md:ml-64" : "md:ml-0"}`}>
        <FloatingWhatsAppButton message="Tengo una consulta." className="z-40" />
        
        {/* Main content area */}
        <main className="flex-1 w-full overflow-y-auto bg-gray-100 p-3 md:p-6 mt-14 md:mt-0">
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

// Add this CSS to your global styles or App.css
const styles = `
  .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
`;

export default ConstructionDashboard;
