import React from 'react';

const FeedInfoCard = ({ title, children, icon }) => (
  <div className="bg-white rounded-lg p-3.5 md:p-4 shadow-sm border border-gray-200">
    <div className="flex items-center gap-2 mb-3">
      <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center text-blue-600">
        {icon}
      </div>
      <h3 className="font-semibold text-gray-800 text-sm md:text-base truncate">{title}</h3>
    </div>
    {children}
  </div>
);

export default FeedInfoCard; 