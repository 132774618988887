import React, { useState } from "react";

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-full">
    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const VideoCard = ({ title, videoSrc, isLoaded, onLoad, downloadText, description }) => (
  <div className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all hover:shadow-xl">
    <div className="p-4 bg-gradient-to-r from-blue-600 to-blue-700">
      <h3 className="text-xl font-bold text-white flex items-center">
        {!isLoaded && (
          <div className="mr-2 animate-pulse h-4 w-4 rounded-full bg-blue-300"></div>
        )}
        {isLoaded ? title : "Cargando video..."}
      </h3>
      {description && (
        <p className="text-blue-100 text-sm mt-1">{description}</p>
      )}
    </div>
    <div className="p-4">
      <div className="relative aspect-video bg-gray-100 rounded-lg overflow-hidden shadow-inner">
        {!isLoaded && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <LoadingSpinner />
          </div>
        )}
        <video
          className={`w-full h-full transition-opacity duration-300 ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
          src={videoSrc}
          controls
          preload="auto"
          onLoadedData={onLoad}
          poster="/video-placeholder.jpg"
        />
      </div>
      <div className="mt-4 space-y-2">
        {isLoaded ? (
          <>
            <a
              href={videoSrc}
              className="block w-full py-3 bg-blue-600 text-white rounded-lg text-center font-semibold hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:outline-none"
              download
            >
              <span className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                </svg>
                {downloadText}
              </span>
            </a>
            <button 
              onClick={() => {
                const videoElement = document.querySelector(`video[src="${videoSrc}"]`);
                if (videoElement) {
                  videoElement.requestFullscreen();
                }
              }}
              className="block w-full py-2 border border-blue-600 text-blue-600 rounded-lg text-center font-medium hover:bg-blue-50 transition-colors focus:ring-2 focus:ring-blue-500 focus:outline-none"
            >
              <span className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
                </svg>
                Ver en pantalla completa
              </span>
            </button>
          </>
        ) : (
          <div className="h-12 bg-gray-200 rounded-lg animate-pulse"></div>
        )}
      </div>
    </div>
  </div>
);

const Timelapse = ({ videoSrc, userdata }) => {
  const [isFirstVideoLoaded, setIsFirstVideoLoaded] = useState(false);
  const [isSecondVideoLoaded, setIsSecondVideoLoaded] = useState(false);

  // Return a friendly message if required props are missing
  if (!videoSrc || !userdata?.processedVideoUrl) {
    return (
      <div className="max-w-7xl mx-auto p-8 text-center">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-md">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                No se encontraron videos para mostrar. Por favor, verifica que los videos estén disponibles.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <h2 className="text-3xl font-bold text-center mb-2 text-gray-800">
        Videos del Proyecto
      </h2>
      <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
        Para una experiencia óptima, recomendamos descargar los videos para visualizarlos sin interrupciones.
      </p>
      
      <div className="grid md:grid-cols-2 gap-8">
        <VideoCard
          title="Video Original"
          description="Grabación original sin procesar"
          videoSrc={videoSrc}
          isLoaded={isFirstVideoLoaded}
          onLoad={() => setIsFirstVideoLoaded(true)}
          downloadText="Descargar Video Original"
        />
        <VideoCard
          title="Video Procesado"
          description="Versión editada"
          videoSrc={userdata.processedVideoUrl}
          isLoaded={isSecondVideoLoaded}
          onLoad={() => setIsSecondVideoLoaded(true)}
          downloadText="Descargar Video Procesado"
        />
      </div>
      
      {(isFirstVideoLoaded && isSecondVideoLoaded) && (
        <div className="mt-8 bg-blue-50 border border-blue-200 rounded-lg p-4 text-sm text-blue-700">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
              </svg>
            </div>
            <p className="ml-3">
              Consejo: Para ver los videos sin interrupciones, recomendamos descargarlos y abrirlos en pantalla completa.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Timelapse;