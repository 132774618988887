import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Send, User, Mail, Phone } from 'lucide-react';
import { collection, getDocs, doc, setDoc, addDoc} from "firebase/firestore";
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { db } from './firebase-config';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const FormContainer = styled.form`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  animation: ${fadeIn} 0.6s ease-out;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const FormTitle = styled.h1`
  text-align: center;
  color: #1a1a1a;
  margin: 2rem 0 1rem;
  font-size: 2.5em;
  font-weight: 700;
  animation: ${fadeIn} 0.8s ease-out;
  background: linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FormSubtitle = styled.h2`
  text-align: center;
  color: #666;
  font-size: 1.1em;
  line-height: 1.6;
  margin: 0 auto 2rem;
  max-width: 600px;
  animation: ${fadeIn} 1s ease-out;
  font-weight: 400;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  position: relative;
  animation: ${slideIn} 0.5s ease-out;
  animation-fill-mode: both;
  animation-delay: ${props => props.$delay || '0s'};
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #374151;
  transition: color 0.3s ease;
`;

const InputWrapper = styled.div`
  position: relative;
  
  svg {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #6B7280;
    transition: color 0.3s ease;
  }
`;

const baseInputStyles = `
  width: 100%;
  padding: 0.75rem;
  padding-left: 2.5rem;
  border: 2px solid #E5E7EB;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #F9FAFB;
  color: #1F2937;

  &:focus {
    outline: none;
    border-color: #4F46E5;
    box-shadow: 0 0 0 3px rgba(79, 70, 229, 0.1);
    background: #FFFFFF;
  }

  &:hover {
    border-color: #4F46E5;
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const Input = styled.input`
  ${baseInputStyles}
`;

const Select = styled.select`
  ${baseInputStyles}
`;

const PhoneInputGroup = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr;
  gap: 0.5rem;
  align-items: start;
`;

const FormatHint = styled.span`
  display: block;
  font-size: 0.8rem;
  color: #6B7280;
  margin-top: 0.25rem;
  font-style: italic;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CountryCodeSelect = styled(Select)`
  padding-left: 0.75rem;
`;

const PhoneInput = styled(Input)``;

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #4F46E5 0%, #7C3AED 100%);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  transform: translateY(0);
  box-shadow: 0 4px 6px rgba(79, 70, 229, 0.1);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(79, 70, 229, 0.2);
    background: linear-gradient(135deg, #4338CA 0%, #6D28D9 100%);
  }

  &:active {
    transform: translateY(0);
  }

  svg {
    margin-right: 8px;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(4px);
  }
`;

const countryCodes = [
        { code: '1', country: 'Estados Unidos' },
        { code: '1', country: 'Canadá' },
        { code: '7', country: 'Rusia' },
        { code: '20', country: 'Egipto' },
        { code: '27', country: 'Sudáfrica' },
        { code: '30', country: 'Grecia' },
        { code: '31', country: 'Países Bajos' },
        { code: '32', country: 'Bélgica' },
        { code: '33', country: 'Francia' },
        { code: '34', country: 'España' },
        { code: '36', country: 'Hungría' },
        { code: '39', country: 'Italia' },
        { code: '40', country: 'Rumania' },
        { code: '41', country: 'Suiza' },
        { code: '43', country: 'Austria' },
        { code: '44', country: 'Reino Unido' },
        { code: '45', country: 'Dinamarca' },
        { code: '46', country: 'Suecia' },
        { code: '47', country: 'Noruega' },
        { code: '48', country: 'Polonia' },
        { code: '49', country: 'Alemania' },
        { code: '51', country: 'Perú' },
        { code: '52', country: 'México' },
        { code: '53', country: 'Cuba' },
        { code: '54', country: 'Argentina' },
        { code: '55', country: 'Brasil' },
        { code: '56', country: 'Chile' },
        { code: '57', country: 'Colombia' },
        { code: '58', country: 'Venezuela' },
        { code: '60', country: 'Malasia' },
        { code: '61', country: 'Australia' },
        { code: '62', country: 'Indonesia' },
        { code: '63', country: 'Filipinas' },
        { code: '64', country: 'Nueva Zelanda' },
        { code: '65', country: 'Singapur' },
        { code: '66', country: 'Tailandia' },
        { code: '81', country: 'Japón' },
        { code: '82', country: 'Corea del Sur' },
        { code: '84', country: 'Vietnam' },
        { code: '86', country: 'China' },
        { code: '90', country: 'Turquía' },
        { code: '91', country: 'India' },
        { code: '92', country: 'Pakistán' },
        { code: '93', country: 'Afganistán' },
        { code: '94', country: 'Sri Lanka' },
        { code: '95', country: 'Myanmar' },
        { code: '98', country: 'Irán' },
        { code: '212', country: 'Marruecos' },
        { code: '213', country: 'Argelia' },
        { code: '216', country: 'Túnez' },
        { code: '218', country: 'Libia' },
        { code: '220', country: 'Gambia' },
        { code: '221', country: 'Senegal' },
        { code: '222', country: 'Mauritania' },
        { code: '223', country: 'Malí' },
        { code: '224', country: 'Guinea' },
        { code: '225', country: 'Costa de Marfil' },
        { code: '226', country: 'Burkina Faso' },
        { code: '227', country: 'Níger' },
        { code: '228', country: 'Togo' },
        { code: '229', country: 'Benín' },
        { code: '230', country: 'Mauricio' },
        { code: '231', country: 'Liberia' },
        { code: '232', country: 'Sierra Leona' },
        { code: '233', country: 'Ghana' },
        { code: '234', country: 'Nigeria' },
        { code: '235', country: 'Chad' },
        { code: '236', country: 'República Centroafricana' },
        { code: '237', country: 'Camerún' },
        { code: '238', country: 'Cabo Verde' },
        { code: '239', country: 'Santo Tomé y Príncipe' },
        { code: '240', country: 'Guinea Ecuatorial' },
        { code: '241', country: 'Gabón' },
        { code: '242', country: 'República del Congo' },
        { code: '243', country: 'República Democrática del Congo' },
        { code: '244', country: 'Angola' },
        { code: '245', country: 'Guinea-Bisáu' },
        { code: '246', country: 'Diego García' },
        { code: '247', country: 'Ascensión' },
        { code: '248', country: 'Seychelles' },
        { code: '249', country: 'Sudán' },
        { code: '250', country: 'Ruanda' },
        { code: '251', country: 'Etiopía' },
        { code: '252', country: 'Somalia' },
        { code: '253', country: 'Yibuti' },
        { code: '254', country: 'Kenia' },
        { code: '255', country: 'Tanzania' },
        { code: '256', country: 'Uganda' },
        { code: '257', country: 'Burundi' },
        { code: '258', country: 'Mozambique' },
        { code: '260', country: 'Zambia' },
        { code: '261', country: 'Madagascar' },
        { code: '262', country: 'Reunión' },
        { code: '263', country: 'Zimbabue' },
        { code: '264', country: 'Namibia' },
        { code: '265', country: 'Malaui' },
        { code: '266', country: 'Lesoto' },
        { code: '267', country: 'Botsuana' },
        { code: '268', country: 'Suazilandia' },
        { code: '269', country: 'Comoras' },
        { code: '290', country: 'Santa Elena' },
        { code: '291', country: 'Eritrea' },
        { code: '297', country: 'Aruba' },
        { code: '298', country: 'Islas Feroe' },
        { code: '299', country: 'Groenlandia' },
        { code: '350', country: 'Gibraltar' },
        { code: '351', country: 'Portugal' },
        { code: '352', country: 'Luxemburgo' },
        { code: '353', country: 'Irlanda' },
        { code: '354', country: 'Islandia' },
        { code: '355', country: 'Albania' },
        { code: '356', country: 'Malta' },
        { code: '357', country: 'Chipre' },
        { code: '358', country: 'Finlandia' },
        { code: '359', country: 'Bulgaria' },
        { code: '370', country: 'Lituania' },
        { code: '371', country: 'Letonia' },
        { code: '372', country: 'Estonia' },
        { code: '373', country: 'Moldavia' },
        { code: '374', country: 'Armenia' },
        { code: '375', country: 'Bielorrusia' },
        { code: '376', country: 'Andorra' },
        { code: '377', country: 'Mónaco' },
        { code: '378', country: 'San Marino' },
        { code: '379', country: 'Ciudad del Vaticano' },
        { code: '380', country: 'Ucrania' },
        { code: '381', country: 'Serbia' },
        { code: '382', country: 'Montenegro' },
        { code: '383', country: 'Kosovo' },
        { code: '385', country: 'Croacia' },
        { code: '386', country: 'Eslovenia' },
        { code: '387', country: 'Bosnia y Herzegovina' },
        { code: '389', country: 'Macedonia del Norte' },
        { code: '420', country: 'República Checa' },
        { code: '421', country: 'Eslovaquia' },
        { code: '423', country: 'Liechtenstein' },
        { code: '500', country: 'Islas Malvinas' },
        { code: '501', country: 'Belice' },
        { code: '502', country: 'Guatemala' },
        { code: '503', country: 'El Salvador' },
        { code: '504', country: 'Honduras' },
        { code: '505', country: 'Nicaragua' },
        { code: '506', country: 'Costa Rica' },
        { code: '507', country: 'Panamá' },
        { code: '508', country: 'San Pedro y Miquelón' },
        { code: '509', country: 'Haití' },
        { code: '590', country: 'Guadalupe' },
        { code: '591', country: 'Bolivia' },
        { code: '592', country: 'Guyana' },
        { code: '593', country: 'Ecuador' },
        { code: '594', country: 'Guayana Francesa' },
        { code: '595', country: 'Paraguay' },
        { code: '596', country: 'Martinica' },
        { code: '597', country: 'Surinam' },
        { code: '598', country: 'Uruguay' },
        { code: '599', country: 'Curazao' },
        { code: '670', country: 'Timor Oriental' },
        { code: '672', country: 'Antártida' },
        { code: '673', country: 'Brunéi' },
        { code: '674', country: 'Nauru' },
        { code: '675', country: 'Papúa Nueva Guinea' },
        { code: '676', country: 'Tonga' },
        { code: '677', country: 'Islas Salomón' },
        { code: '678', country: 'Vanuatu' },
        { code: '679', country: 'Fiyi' },
        { code: '680', country: 'Palaos' },
        { code: '681', country: 'Wallis y Futuna' },
        { code: '682', country: 'Islas Cook' },
        { code: '683', country: 'Niue' },
        { code: '685', country: 'Samoa' },
        { code: '686', country: 'Kiribati' },
        { code: '687', country: 'Nueva Caledonia' },
        { code: '688', country: 'Tuvalu' },
        { code: '689', country: 'Polinesia Francesa' },
        { code: '690', country: 'Tokelau' },
        { code: '691', country: 'Micronesia' },
        { code: '692', country: 'Islas Marshall' },
        { code: '850', country: 'Corea del Norte' },
        { code: '852', country: 'Hong Kong' },
        { code: '853', country: 'Macao' },
        { code: '855', country: 'Camboya' },
        { code: '856', country: 'Laos' },
        { code: '880', country: 'Bangladesh' },
        { code: '886', country: 'Taiwán' },
        { code: '960', country: 'Maldivas' },
        { code: '961', country: 'Líbano' },
        { code: '962', country: 'Jordania' },
        { code: '963', country: 'Siria' },
        { code: '964', country: 'Irak' },
        { code: '965', country: 'Kuwait' },
        { code: '966', country: 'Arabia Saudita' },
        { code: '967', country: 'Yemen' },
        { code: '968', country: 'Omán' },
        { code: '970', country: 'Palestina' },
        { code: '971', country: 'Emiratos Árabes Unidos' },
        { code: '972', country: 'Israel' },
        { code: '973', country: 'Baréin' },
        { code: '974', country: 'Catar' },
        { code: '975', country: 'Bután' },
        { code: '976', country: 'Mongolia' },
        { code: '977', country: 'Nepal' },
        { code: '992', country: 'Tayikistán' },
        { code: '993', country: 'Turkmenistán' },
        { code: '994', country: 'Azerbaiyán' },
        { code: '995', country: 'Georgia' },
        { code: '996', country: 'Kirguistán' },
        { code: '998', country: 'Uzbekistán' },
      ];
  // Add more country codes as needed

const NotificationForm = (identificador) => {
  const navigate = useNavigate();
  if(!identificador){
    toast.error("Error en el identificador, intente mas tarde");
    navigate('/');
  }
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    identifier: identificador.id.identifier,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formattedPhoneNumber = `${formData.countryCode}${formData.phoneNumber.replace(/\s+/g, '')}`;
      
      const dataToSubmit = {
        ...formData,
        phoneNumber: formattedPhoneNumber,
      };
      delete dataToSubmit.countryCode;
      
      await addDoc(collection(db, 'notificaciones'), dataToSubmit);
      
      toast.success('¡Gracias por suscribirte! Te mantendremos informado.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          background: '#10B981',
          color: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }
      });

      setFormData({
        name: '',
        surname: '',
        email: '',
        countryCode: '',
        phoneNumber: '',
        identifier: identificador.id.identifier,
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Ha ocurrido un error. Por favor, inténtalo nuevamente.', {
        position: "top-center",
        style: {
          background: '#EF4444',
          color: '#FFFFFF',
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <FormTitle>Automatizá las comunicaciones de tu obra</FormTitle>
      <FormSubtitle>
        Completá la información del formulario y te enviaremos actualizaciones periódicas 
        sobre el progreso de tu obra para mantenerte informado y que puedas compartir 
        los avances con el mundo.
      </FormSubtitle>
      <FormContainer onSubmit={handleSubmit}>
        <FormGroup $delay="0.1s">
          <Label htmlFor="name">Nombre</Label>
          <InputWrapper>
            <User size={18} />
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Ingresa tu nombre"
              required
            />
          </InputWrapper>
        </FormGroup>
        <FormGroup $delay="0.2s">
          <Label htmlFor="surname">Apellido</Label>
          <InputWrapper>
            <User size={18} />
            <Input
              type="text"
              id="surname"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              placeholder="Ingresa tu apellido"
              required
            />
          </InputWrapper>
        </FormGroup>
        <FormGroup $delay="0.3s">
          <Label htmlFor="email">Email</Label>
          <InputWrapper>
            <Mail size={18} />
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="ejemplo@correo.com"
              required
            />
          </InputWrapper>
        </FormGroup>
        <FormGroup $delay="0.4s">
          <Label htmlFor="phoneNumber">Número de teléfono</Label>
          <PhoneInputGroup>
            <CountryCodeSelect
              id="countryCode"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              required
            >
              <option value="">Código de país</option>
              {countryCodes.map((country) => (
                <option key={country.code} value={country.code}>
                  +{country.code} ({country.country})
                </option>
              ))}
            </CountryCodeSelect>
            <PhoneWrapper>
              <InputWrapper>
                <Phone size={18} />
                <PhoneInput
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  placeholder="Ingresa tu número"
                  pattern="[0-9 ]*"
                  title="Solo números y espacios permitidos"
                  required
                />
              </InputWrapper>
              <FormatHint>
                Ejemplo: Para Argentina (+54): 11 1234 5678
              </FormatHint>
            </PhoneWrapper>
          </PhoneInputGroup>
        </FormGroup>
        <SubmitButton type="submit">
          <Send size={18} />
          Suscribirse
        </SubmitButton>
      </FormContainer>
    </>
  );
};

export default NotificationForm;